import React from "react";
import { APIPath, ListsConfig, RoutePath } from "data";
import { ListBuilderComp, PageBuilderComp } from "components";
import { WorkWithFormType } from "types";

const ListWorkWithForm: React.FC = function () {
  return (
    <PageBuilderComp
      title="فرم های همکاری با ما"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "فرم های همکاری با ما" },
      ]}
    >
      <ListBuilderComp
        pageSize={10}
        fields={ListsConfig.workWithUs.fields}
        api={APIPath.workWith()}
        onlyShow
        deleteAction={(e: WorkWithFormType) => APIPath.workWith(e.id)}
        editAction={(e: WorkWithFormType) => `${RoutePath.work.detail(e.id)}`}
      />
    </PageBuilderComp>
  );
};
export default ListWorkWithForm;
