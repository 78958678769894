import { Breadcrumbs, SpeedDial, SpeedDialAction } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { TbMenu2 } from "react-icons/tb";
import { Link } from "react-router-dom";
import { ButtonComp } from "utilities";
import "./pageBuilder.style.scss";
import { __pageBuilderProps } from "./pageBuilder.type";

const __PageBuilderComp: React.FC<PropsWithChildren<__pageBuilderProps>> = (
  props: PropsWithChildren<__pageBuilderProps>
) => {
  return (
    <div
      style={props.style}
      className={`app-page-base ${props.className ? props.className : ""}`}
    >
      <div className="page-header mb-3">
        <div>
          <h1 className="typo-h5 mb-1">{props.title}</h1>
          <Breadcrumbs className="typo-p3-i" aria-label="breadcrumb">
            {props.breadcrumb?.map((item, index) => {
              if (item.path)
                return (
                  <Link key={index} to={item.path}>
                    {item.title}
                  </Link>
                );
              else return <p key={index}>{item.title}</p>;
            })}
          </Breadcrumbs>
        </div>
        {props.actions?.map((item, index) => (
          <ButtonComp
            key={index}
            onClick={item.onClick}
            className="d-pre-md-none-i mr-1-i"
            variant="outlined"
            startIcon={item.icon()}
          >
            {item.title}
          </ButtonComp>
        ))}
      </div>
      {props.children}
      {props.actions?.length ? (
        <SpeedDial
          onClick={
            props.actions.length === 1 ? props.actions[0].onClick : undefined
          }
          ariaLabel="SpeedDial basic example"
          className="responsive-actions d-md-none-i"
          icon={
            props.actions.length === 1 ? props.actions[0].icon() : <TbMenu2 />
          }
        >
          {props.actions.length > 1
            ? props.actions.map((action, index) => (
                <SpeedDialAction
                  key={index}
                  icon={action.icon()}
                  tooltipTitle={action.title}
                />
              ))
            : null}
        </SpeedDial>
      ) : null}
    </div>
  );
};

export default __PageBuilderComp;
