// import { AppMainLang } from 'app/app.lang';
import { AppStorage } from "scripts";
import { AppContextType, AuthStatusEnum, LanguageEnum } from "types";

// initial value of the global state in
export const globalContextInitialValue: AppContextType = {
  update: (...e) => {
    return "this one is going to change in container.tsx" + e.length;
  },
  profile: null, // authorized user data
  authStatus: AuthStatusEnum.pending, // status authorization status
  language: AppStorage.defaultLang.get() ?? LanguageEnum.fa,
  formDefaultValues: [],
};
