"use client";
import React, { PropsWithChildren, useContext, useState } from "react";
import { __BaseCalendarProps, __MonthType } from "./baseCalendar.type";
import { __useDateUtils } from "./baseCalendar.scripts";
import { ButtonComp, IconButtonComp } from "utilities";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";
import "./baseCalendar.style.scss";
import { GlobalContext } from "context";
import { Controller } from "react-hook-form";

const __BaseCalendarComp: React.FC<PropsWithChildren<__BaseCalendarProps>> = (
  props: PropsWithChildren<__BaseCalendarProps>
) => {
  const [status, setStatus] = useState<"month" | "year">("month");
  const [hoveredDate, setHoveredDate] = useState<Date>();
  const data = __useDateUtils(new Date(props.value ?? new Date()), "fa-ir");

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <div className="app-base-calendar-util">
          <div className="calendar-head">
            <IconButtonComp
              onClick={() => {
                if (status === "year")
                  data.set(data.base.year.number - 1, data.base.month.number);
                else
                  data.set(
                    data.base.year.number -
                      (data.base.month.number === 1 ? 1 : 0),
                    data.base.month.number === 1
                      ? 12
                      : data.base.month.number - 1
                  );
              }}
            >
              <TbChevronRight />
            </IconButtonComp>
            <ButtonComp onClick={() => setStatus("year")} variant="outlined">
              {data.base.year.title}{" "}
              {status === "month" ? data.base.month.title : ""}
            </ButtonComp>
            <IconButtonComp
              onClick={() => {
                if (status === "year")
                  data.set(data.base.year.number + 1, data.base.month.number);
                else
                  data.set(
                    data.base.year.number +
                      (data.base.month.number === 12 ? 1 : 0),
                    data.base.month.number === 12
                      ? 1
                      : data.base.month.number + 1
                  );
              }}
            >
              <TbChevronLeft />
            </IconButtonComp>
          </div>
          {status === "month" ? (
            <div className="week-days">
              {data.week.map((day, index) => (
                <span key={index}>{day.short}</span>
              ))}
            </div>
          ) : null}
          {status === "month" ? (
            <div
              onMouseOut={() => setHoveredDate(undefined)}
              className="month-days"
            >
              {data.days.map((week, weekIndex) => (
                <div key={weekIndex} className="week">
                  {week.map((day, dayIndex) => (
                    <button
                      onClick={() => {
                        if (props.onDayClick) props.onDayClick(day.date);
                      }}
                      onMouseOver={() => setHoveredDate(day.date)}
                      className={
                        props.dayClassName
                          ? props.dayClassName(day.date, hoveredDate)
                          : ""
                      }
                      key={dayIndex}
                    >
                      {day.dayTitle}
                    </button>
                  ))}
                </div>
              ))}
            </div>
          ) : null}
          {status === "year" ? (
            <div className="year-month">
              {data.months.map((session, sessionIndex) => (
                <div key={sessionIndex} className="season">
                  {session.map((month) => (
                    <ButtonComp
                      variant="text"
                      key={month.number}
                      onClick={() => {
                        data.set(data.base.year.number, month.number);
                        setStatus("month");
                      }}
                    >
                      {month.title}
                    </ButtonComp>
                  ))}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
    />
  );
};

export default __BaseCalendarComp;
