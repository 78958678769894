import React, { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { __MultiAttachProps } from "./multiFileAttach.type";
import { FileAttachmentType } from "types";
import "./multiFileAttach.style.scss";
import AttachedCardComp from "./attachedCard/attachedCard.index";
import { toast } from "react-toastify";
import InprogressCard from "./inprogressCard/inprogressCard.index";
import { RiUploadCloud2Line } from "react-icons/ri";
import { FormHelperText } from "@mui/material";

const __MultiFileAttachComp: React.FC<__MultiAttachProps> = (
  props: __MultiAttachProps
) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [hover, setHover] = useState<boolean>(false); // to show drop file cover on drop file
  const [inprogress, setInprogress] = useState<File[]>([]);

  function onChangeHandler(values: FileAttachmentType[]) {
    // on select file from local
    if (fileInputRef.current?.files?.length) {
      // check length of list
      if (
        fileInputRef.current?.files.length <=
        props.limit - inprogress.length - values.length
      ) {
        const files: File[] = [];
        for (let i = 0; i < fileInputRef.current.files.length; i++) {
          files.push(fileInputRef.current.files[i]);
        }
        setInprogress([...inprogress, ...files]);
      } else toast.error(`شما میتوانید حداکثر ${props.limit} فایل آپلود کنید`);
    }
  }

  function onDropHandler(e: React.DragEvent<HTMLDivElement>) {
    // on image drop, file will upload
    e.preventDefault();
    e.stopPropagation();
    setHover(false);
    const files: File[] = [];
    // if ()
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      files.push(e.dataTransfer.files[i]);
    }
    if (files.length) {
      setInprogress([...inprogress, ...files]);
    }
  }

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => {
        const values: FileAttachmentType[] =
          field.value && field.value.length ? field.value : [];
        let skeleton = props.limit - values.length - inprogress.length;
        skeleton = skeleton > 0 ? skeleton : 0;
        return (
          <div
            className={`ens-multi-file-attachment ${
              props.className ? props.className : ""
            } ${Boolean(fieldState.error?.message?.length) ? "error" : ""}`}
          >
            <div
              onDrop={onDropHandler}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setHover(true); // set box status to wait for drop on drag file
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setHover(false); // set box status to normal on drag leave
              }}
            >
              <input
                accept={props.accept}
                multiple={true}
                onChange={() => onChangeHandler(values)}
                ref={fileInputRef}
                className="d-none"
                type="file"
                name="file"
                key={inprogress
                  .map((i) => i.name + i.size + i.type + i.lastModified)
                  .join("_")}
              />
              <div className="title">
                <p className="typo-p3 typo-gray-2">
                  {props.label ? props.label : ""}
                </p>
                <span className="typo-p4 typo-gray-3">
                  {values.length} / {props.limit}
                </span>
              </div>
              <div className="files-list">
                <div>
                  {values.map((item, index) => (
                    <AttachedCardComp
                      onRemove={() => {
                        const temp = [...values];
                        temp.splice(index, 1);
                        field.onChange(temp);
                        if (
                          fileInputRef.current &&
                          fileInputRef.current.files
                        ) {
                          const files = Array.from(fileInputRef.current.files);
                          files.splice(index, 1);
                          const list = new DataTransfer();
                          for (const file of files) {
                            list.items.add(file);
                          }
                          fileInputRef.current.files = list.files;
                        }
                      }}
                      data={item}
                      key={item.id}
                    />
                  ))}
                  {inprogress.map((item, index) => (
                    <InprogressCard
                      onEnd={(e) => {
                        if (e) field.onChange([...values, e]);
                        const temp = [...inprogress];
                        temp.splice(index, 1);
                        setInprogress(temp);
                      }}
                      key={index}
                      file={item}
                    />
                  ))}
                  {inprogress.length === 0 && values.length === 0 ? (
                    <div
                      onClick={() => fileInputRef.current?.click()}
                      className="empty-state pointer"
                    >
                      <RiUploadCloud2Line
                        size={48}
                        className="mb-2 typo-gray-3"
                      />
                      <p className="typo-p4 typo-gray-3">
                        برای انتخاب فایل کلیک کنید و یا فایل ها را بکشید و رها
                        کنید
                      </p>
                    </div>
                  ) : null}
                  {skeleton < props.limit &&
                    Array.from(Array(skeleton).keys()).map((item, index) => (
                      <div
                        onClick={() => fileInputRef.current?.click()}
                        className="skeleton"
                        key={index}
                      >
                        <RiUploadCloud2Line className="typo-gray-4" size={32} />
                      </div>
                    ))}
                  {hover ? (
                    <div className="hover typo-p3 typo-gray-3">
                      برای آپلود، فایل ها را اینجا رها کنید
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {Boolean(fieldState.error?.message?.length) || props.hint ? (
              <FormHelperText
                error={Boolean(fieldState.error?.message?.length)}
              >
                {fieldState.error?.message?.length
                  ? fieldState.error.message
                  : props.hint}
              </FormHelperText>
            ) : null}
          </div>
        );
      }}
    />
  );
};

export default __MultiFileAttachComp;
