import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { __SelectProps } from "./select.type";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IconButtonComp } from "utilities";
import { TbX } from "react-icons/tb";
const __SelectComp: React.FC<__SelectProps> = (props: __SelectProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => (
        <FormControl
          className={`mb-2-i ${props.className ? props.className : ""}`}
          fullWidth
        >
          <InputLabel>{props.label ? props.label : props.name}</InputLabel>
          <Select
            disabled={props.disabled}
            variant={props.variant ? props.variant : "outlined"}
            error={Boolean(fieldState.error)}
            input={
              <OutlinedInput label={props.label ? props.label : props.name} />
            }
            renderValue={(selected: string) =>
              props.options.find((item) => selected === item.payload)?.title
            }
            {...field}
            endAdornment={
              field.value && field.value.length ? (
                <InputAdornment position="end">
                  <IconButtonComp
                    size="small"
                    onClick={() => field.onChange(null)}
                  >
                    <TbX />
                  </IconButtonComp>
                </InputAdornment>
              ) : undefined
            }
            value={field.value ? field.value : ""}
          >
            {props.options.map((item) => (
              <MenuItem key={item.payload} value={item.payload}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default __SelectComp;
