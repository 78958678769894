import { FormHelperText } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { __TextEditorProps } from "./textEditor4.type";
import "./textEditor4.style.scss";
import __EditorCoreComp from "./editor/editor.index";

const __TextEditor4Comp: React.FC<__TextEditorProps> = (
  props: __TextEditorProps
) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => (
        <div
          className={`admin-text-editor-container4 ${
            props.className ? props.className : ""
          } ${Boolean(fieldState.error?.message?.length) ? "error" : ""}`}
        >
          <__EditorCoreComp
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            label={props.label}
          />
          {Boolean(fieldState.error?.message?.length) || props.hint ? (
            <FormHelperText error={Boolean(fieldState.error?.message?.length)}>
              {fieldState.error?.message?.length
                ? fieldState.error.message
                : props.hint}
            </FormHelperText>
          ) : null}
        </div>
      )}
    />
  );
};

export default __TextEditor4Comp;
