import { APIPath } from "data";
import React from "react";
import { AiOutlineFileText } from "react-icons/ai";
import { TiTimes } from "react-icons/ti";
import { RestAPI } from "scripts";
import { FileAttachmentType } from "types";
import { IconButtonComp } from "utilities";
import "./attachedCard.style.scss";

const AttachedCardComp: React.FC<{
  data: FileAttachmentType;
  onRemove?: () => void;
}> = (props: { data: FileAttachmentType; onRemove?: () => void }) => {
  const isImage = /(jpg|jpeg|png|webp|avif|gif|svg)$/.test(
    props.data.file.split(".").pop()?.toLowerCase() ?? ""
  );
  const name = props.data.file.split("/").pop();
  return (
    <a
      href={props.data.file}
      download
      rel="noreferrer"
      target="_blank"
      className="attached-card"
    >
      {isImage ? (
        <img src={props.data.file} />
      ) : (
        <div>
          <AiOutlineFileText size={32} className="typo-gray-3 mb-2" />
          <p className="typo-p4 typo-gray-3">{name}</p>
        </div>
      )}
      {props.onRemove ? (
        <IconButtonComp
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // RestAPI.delete<FileAttachmentType>(APIPath.attachment(props.data.id));
            if (props.onRemove) props.onRemove();
          }}
          size="small"
        >
          <TiTimes />
        </IconButtonComp>
      ) : null}
    </a>
  );
};

export default AttachedCardComp;
