const api = process.env.REACT_APP_API;

// paths of api call's urls
export const __API = {
  auth: {
    refresh: api + "/account/auth/token/refresh/",
    signIn: api + "/account/auth/login/",
    me: api + "/account/user/me/",
  },
  attachment: (id?: number | string) =>
    `${api}/core/image-attachment/${id ? id + "/" : ""}`,
  product: (slug?: string) => `${api}/product/${slug ? slug + "/" : ""}`,
  productCategory: (slug?: string) =>
    `${api}/product/category/${slug ? slug + "/" : ""}`,
  post: (slug?: string) => `${api}/blog/post/${slug ? slug + "/" : ""}`,
  blogCategory: (slug?: string) =>
    `${api}/blog/category/${slug ? slug + "/" : ""}`,
  contact: (id?: number) => `${api}/account/contact_us/${id ? id + "/" : ""}`,
  workWith: (id?: number) => `${api}/core/workwithus/${id ? id + "/" : ""}`,
  customer: (id?: number) => `${api}/account/customer/${id ? id + "/" : ""}`,
  gallery: (id?: number) => `${api}/core/gallery_item/${id ? id + "/" : ""}`,
};
