import React, { useImperativeHandle, useRef, useState } from "react";
import { __EditorToolbarProps, __EditorToolbarRef } from "./toolbar.type";
import { IconButtonComp } from "utilities";
import { BsCardImage, BsEmojiSunglasses, BsFillGridFill } from "react-icons/bs";
import { CircularProgress, Menu } from "@mui/material";
import __URLToolbarOptionComp from "../URL/URL.index";
import __GalleryToolbarOptionComp from "../../../gallery/gallery.index";
import { MdEuroSymbol } from "react-icons/md";
import { emojiList, symbolsList } from "./toolbar.data";
import { HiVideoCamera } from "react-icons/hi";

const __EditorToolbarComp: React.ForwardRefRenderFunction<
  __EditorToolbarRef,
  __EditorToolbarProps
> = (props: __EditorToolbarProps, ref) => {
  const [gallery, setGallery] = useState<"image" | "video">();
  const [loading, setLoading] = useState<boolean>(false);
  const imageRef = useRef<HTMLInputElement>(null);
  const [symbolEl, setSymbolEl] = useState<HTMLElement>();
  const [emojiEl, setEmojiEl] = useState<HTMLElement>();

  useImperativeHandle(ref, () => ({
    changeIndex: (index?: number) => {
      console.log("update requested");
    },
  }));

  function onSymbolClick(e: string) {
    props.getEditor()?.core.functions.insertHTML(e);
  }

  return (
    <>
      <input
        onChange={(e) => {
          if (e.target.files)
            props.getEditor()?.core.functions.insertImage(e.target.files);
        }}
        ref={imageRef}
        accept="image/*"
        type="file"
        className="d-none"
      />
      <__GalleryToolbarOptionComp
        display={gallery}
        onClose={() => setGallery(undefined)}
        onSubmit={(e) => {
          let inserted = "";
          if (gallery === "image") inserted = `<img src="${e.file}" />`;
          else if (gallery === "video")
            inserted = `<video controls src="${e.file}" />`;
          props.getEditor()?.core.functions.insertHTML(inserted);
          setGallery(undefined);
        }}
      />
      <Menu
        anchorEl={symbolEl}
        open={Boolean(symbolEl)}
        onClose={() => setSymbolEl(undefined)}
      >
        <div style={{ maxWidth: 400 }}>
          {symbolsList.map((item, index) => (
            <IconButtonComp
              style={{ width: 54, height: 54 }}
              onClick={() => {
                onSymbolClick(item);
                setSymbolEl(undefined);
              }}
              key={index}
            >
              <span dangerouslySetInnerHTML={{ __html: item }}></span>
            </IconButtonComp>
          ))}
        </div>
      </Menu>
      <Menu
        anchorEl={emojiEl}
        open={Boolean(emojiEl)}
        onClose={() => setEmojiEl(undefined)}
      >
        <div style={{ maxWidth: 400 }}>
          {emojiList.map((item, index) => (
            <IconButtonComp
              style={{ width: 54, height: 54 }}
              onClick={() => {
                onSymbolClick(item);
                setEmojiEl(undefined);
              }}
              key={index}
            >
              {item}
            </IconButtonComp>
          ))}
        </div>
      </Menu>
      <div className={`custom-toolbar typo-gray-2`}>
        <p className="typo-p3 typo-gray-3">{props.label ? props.label : ""}</p>
        <IconButtonComp onClick={(e) => setEmojiEl(e.currentTarget)}>
          <BsEmojiSunglasses fontSize={18} />
        </IconButtonComp>
        <IconButtonComp onClick={(e) => setSymbolEl(e.currentTarget)}>
          <MdEuroSymbol fontSize={18} />
        </IconButtonComp>
        <IconButtonComp onClick={(e) => setGallery("video")}>
          <HiVideoCamera fontSize={18} />
        </IconButtonComp>
        <IconButtonComp onClick={(e) => setGallery("image")}>
          <BsCardImage fontSize={18} />
        </IconButtonComp>
      </div>
      {loading ? (
        <div className="loading-state">
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default React.forwardRef(__EditorToolbarComp);
