import React from "react";
import { APIPath, ListsConfig, RoutePath } from "data";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ListBuilderComp, PageBuilderComp } from "components";
import { CustomerType } from "types";

const ListCustomer: React.FC = function () {
  const history = useHistory();

  return (
    <PageBuilderComp
      actions={[
        {
          title: "ایجاد مشتری جدید",
          icon: () => <MdAdd />,
          onClick: () => history.push(RoutePath.customer.create),
        },
      ]}
      title="مشتری ها"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "مشتری ها" },
      ]}
    >
      <ListBuilderComp
        pageSize={10}
        fields={ListsConfig.customer.fields}
        api={APIPath.customer()}
        deleteAction={(e: CustomerType) => APIPath.customer(e.id)}
        editAction={(e: CustomerType) => `${RoutePath.customer.edit(e.id)}`}
      />
    </PageBuilderComp>
  );
};
export default ListCustomer;
