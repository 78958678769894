import React from "react";
import { APIPath, ListsConfig, RoutePath } from "data";
import { ListBuilderComp, PageBuilderComp } from "components";
import { ContactFormType } from "types";

const ListContactForm: React.FC = function () {
  return (
    <PageBuilderComp
      title="فرم های تماس"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "فرم های تماس" },
      ]}
    >
      <ListBuilderComp
        pageSize={10}
        fields={ListsConfig.contact.fields}
        api={APIPath.contact()}
        onlyShow
        deleteAction={(e: ContactFormType) => APIPath.contact(e.id)}
        editAction={(e: ContactFormType) => `${RoutePath.contact.detail(e.id)}`}
      />
    </PageBuilderComp>
  );
};
export default ListContactForm;
