import { Button, CircularProgress } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import { Link } from "react-router-dom";
import { __ButtonProps } from "./button.type";
import "./button.style.scss";

const __ButtonComp: React.FC<PropsWithChildren<__ButtonProps>> = (
  props: PropsWithChildren<__ButtonProps>
) => {
  const [loading, setLoading] = useState<boolean>(false);

  let variant: "contained" | "outlined" | undefined = "contained";
  if (props.variant === "outlined") variant = "outlined";
  else if (props.variant === "text") variant = undefined;
  let loadingSize = 20;
  if (props.size === "small") loadingSize = 14;
  else if (props.size === "large") loadingSize = 24;

  function onClickHandler(
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ) {
    if (props.disabled || loading) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (props.onClick) {
      const promise = props.onClick(e);
      // if onClick function return promise we should render loading, because the task is async
      if (promise) {
        setLoading(true);
        promise.then(() => {
          setLoading(false);
        });
      }
    }
  }

  const Main = (
    <Button
      fullWidth={props.fullWidth}
      style={props.style}
      className={`app-button-utility ${props.className ? props.className : ""}`}
      variant={variant}
      size={props.size}
      disabled={props.disabled}
      onClick={!props.href ? onClickHandler : undefined}
      onMouseDown={!props.href ? props.onMouseDown : undefined}
      startIcon={
        props.startIcon ? (
          <span
            className="button-utility-icon"
            style={{ opacity: loading ? 0 : 1 }}
          >
            {props.startIcon}
          </span>
        ) : undefined
      }
      endIcon={
        props.endIcon ? (
          <span
            className="button-utility-icon"
            style={{ opacity: loading ? 0 : 1 }}
          >
            {props.endIcon}
          </span>
        ) : undefined
      }
    >
      <span style={{ opacity: loading ? 0 : 1 }}>{props.children}</span>
      {loading ? (
        <span className="button-loading">
          <CircularProgress color="inherit" size={loadingSize} />
        </span>
      ) : null}
    </Button>
  );

  if (!props.href) return Main;
  else
    return (
      <Link
        onMouseDown={props.onMouseDown}
        onClick={onClickHandler}
        to={{ pathname: props.href }}
      >
        {Main}
      </Link>
    );
};

export default __ButtonComp;
