import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './sidebar.styles.scss';
import logo from 'assets/images/logo.png';
import { TbChevronsRight } from 'react-icons/tb';
import { IconButtonComp } from 'utilities';
import { Button } from '@mui/material';
import { __sidebarData } from './sidebar.data';

const SideBarComp: React.FC = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const Main = (
        <div className="app-main-menu">
            <div>
                <div className="logo-keeper">
                    <div className="logo">
                        <img src={logo} />
                    </div>
                    <h6 className="typo-h6">پنل مدیریت</h6>
                    <IconButtonComp onClick={() => setCollapsed(!collapsed)}>
                        {collapsed ? <TbChevronsRight size={18} /> : null}
                    </IconButtonComp>
                </div>
                {__sidebarData().map((item, i) => (
                    <React.Fragment key={i}>
                        <h6 className="typo-p3 ph-2 pb-1">{item.title}</h6>
                        <div className="item-group">
                            {item.children.map((link, j) => (
                                <NavLink key={j} to={link.link} activeClassName="typo-primary-i">
                                    <Button color={'inherit'} variant="text">
                                        <i>
                                            <link.icon size={18} />
                                        </i>
                                        <p>{link.title}</p>
                                    </Button>
                                </NavLink>
                            ))}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
    return (
        <nav className={`d-pre-md-none ${collapsed ? 'collapsed' : ''}`}>
            <div>{Main}</div>
        </nav>
    );
};

export default SideBarComp;
