import { APIPath } from "data";
import { AppStorage } from "scripts";
import {
  AppContextActionKeyEnum,
  AppContextActionType,
  AuthStatusEnum,
  AuthTokenEnum,
  LanguageEnum,
  ResponseType,
} from "types";
import { __logout } from "./register";

let update: (...e: AppContextActionType[]) => void = () => {
  // we will update this function in algorithm
};

function setDispatcher(e: (...e: AppContextActionType[]) => void) {
  update = e;
}

// add authentication headers to other headers
function generateHeader(object: any = {}): any {
  const header: { [k: string]: any } = {};
  // add authentication header
  if (AppStorage.authToken.get()) {
    header["Authorization"] = "jwt " + AppStorage.authToken.get()?.access;
  }
  // add other headers
  for (const key of Object.keys(object)) {
    header[key] = object[key];
  }
  return header;
}

// delete request
function del<R>(url: string): Promise<ResponseType<R>> {
  let status: number;
  const payload: RequestInit = {
    method: "DELETE",
    headers: generateHeader({ "Content-Type": "application/json" }),
  };
  return new Promise((resolve, reject) => {
    fetch(url, payload)
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then((data) =>
        responseHandler(status, data, url, payload, resolve, reject)
      )
      .catch(() =>
        responseHandler(status, null, url, payload, resolve, reject)
      );
  });
}

// post request
function post<R>(url: string, body: any): Promise<ResponseType<R>> {
  let status: number;
  if (!("lang" in body))
    body.lang = AppStorage.defaultLang.get() ?? LanguageEnum.fa;
  if (body.lang === undefined || body.lang === null) delete body.lang;
  const payload: RequestInit = {
    method: "POST",
    headers: generateHeader({ "Content-Type": "application/json" }),
    body: JSON.stringify(body),
  };
  return new Promise((resolve, reject) => {
    fetch(url, payload)
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then((data) =>
        responseHandler(status, data, url, payload, resolve, reject)
      )
      .catch(() =>
        responseHandler(status, null, url, payload, resolve, reject)
      );
  });
}

// form request (not post, like html form submit)
function form<R>(url: string, body: any): Promise<ResponseType<R>> {
  let status: number;
  if (!("lang" in body))
    body.lang = AppStorage.defaultLang.get() ?? LanguageEnum.fa;
  if (body.lang === undefined || body.lang === null) delete body.lang;
  const payload: RequestInit = {
    method: "POST",
    body: body,
    headers: generateHeader(),
  };
  return new Promise((resolve, reject) => {
    fetch(url, payload)
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then((data) =>
        responseHandler(status, data, url, payload, resolve, reject)
      )
      .catch(() =>
        responseHandler(status, null, url, payload, resolve, reject)
      );
  });
}

// put request
function put<R>(url: string, body: any): Promise<ResponseType<R>> {
  let status: number;
  if (!("lang" in body))
    body.lang = AppStorage.defaultLang.get() ?? LanguageEnum.fa;
  if (body.lang === undefined || body.lang === null) delete body.lang;
  const payload: RequestInit = {
    method: "PUT",
    body: JSON.stringify(body),
    headers: generateHeader({ "Content-Type": "application/json" }),
  };
  return new Promise((resolve, reject) => {
    fetch(url, payload)
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then((data) =>
        responseHandler(status, data, url, payload, resolve, reject)
      )
      .catch(() =>
        responseHandler(status, null, url, payload, resolve, reject)
      );
  });
}

// patch request
function patch<R>(url: string, body: any): Promise<ResponseType<R>> {
  let status: number;
  if (!("lang" in body))
    body.lang = AppStorage.defaultLang.get() ?? LanguageEnum.fa;
  if (body.lang === undefined || body.lang === null) delete body.lang;
  const payload: RequestInit = {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: generateHeader({ "Content-Type": "application/json" }),
  };
  return new Promise((resolve, reject) => {
    fetch(url, payload)
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then((data) =>
        responseHandler(status, data, url, payload, resolve, reject)
      )
      .catch(() =>
        responseHandler(status, null, url, payload, resolve, reject)
      );
  });
}

// get request
function get<R>(
  url: string,
  params: { [k: string]: any } = {}
): Promise<ResponseType<R>> {
  const generatedUrl = new URL(url);
  // add query parameters like filters or pagination parameters
  if (!("lang" in params))
    params.lang = AppStorage.defaultLang.get() ?? LanguageEnum.fa;
  if (params.lang === undefined || params.lang === null) delete params.lang;
  Object.keys(params).forEach((key) => {
    generatedUrl.searchParams.append(key, params[key]);
  });
  let status: number;
  const payload: RequestInit = {
    method: "GET",
    headers: generateHeader({ "Content-Type": "application/json" }),
  };
  return new Promise((resolve, reject) => {
    fetch(generatedUrl.href, payload)
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then((data) =>
        responseHandler(
          status,
          data,
          generatedUrl.href,
          payload,
          resolve,
          reject
        )
      )
      .catch(() =>
        responseHandler(status, null, url, payload, resolve, reject)
      );
  });
}

function upload<R>(
  URL: string,
  formData: any,
  onProgress?: (progress: number) => void
): Promise<ResponseType<R>> {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.upload.addEventListener("progress", function (e) {
      if (onProgress) onProgress(e.loaded);
    });
    request.open("post", URL);
    request.onload = function () {
      if (request.readyState == XMLHttpRequest.DONE)
        resolve({
          status: request.status,
          data: JSON.parse(request.responseText),
        });
      else reject({ status: request.status, data: null });
    };
    request.onerror = () => reject({ status: request.status, data: null });
    request.setRequestHeader(
      "Authorization",
      "jwt " + AppStorage.authToken.get()?.access
    );
    request.timeout = 45000;
    request.send(formData);
  });
}

// validate if request was successful
function responseValidator(status: number): boolean {
  return status >= 200 && status < 300;
}

export const __RestAPI = {
  get,
  post,
  put,
  patch,
  form,
  delete: del,
  upload,
  setDispatcher,
};

function responseHandler(
  status: number,
  data: any,
  url: string,
  payload: RequestInit,
  resolve: (e: any) => void,
  reject: (e: any) => void
) {
  console.log(responseValidator(status));
  if (responseValidator(status)) resolve({ data, status });
  else if (
    status === 401 &&
    AppStorage.tokenStatus.get() !== AuthTokenEnum.invalid
  ) {
    expireHandler().then(() => retry(url, payload, resolve, reject));
  } else reject({ data, status });
}

function retry(
  url: string,
  payload: RequestInit,
  resolve: (e: any) => void,
  reject: (e: any) => void
) {
  let status: number;
  const newPayload = { ...payload };
  newPayload.headers = generateHeader({ "Content-Type": "application/json" });
  fetch(url, newPayload)
    .then(function (response) {
      status = response.status;
      return response.json();
    })
    .then((data) => {
      if (responseValidator(status)) resolve({ data, status });
      else if (status === 401) {
        AppStorage.tokenStatus.set(AuthTokenEnum.invalid);
        AppStorage.authToken.remove();
        update({
          key: AppContextActionKeyEnum.authStatus,
          value: AuthStatusEnum.invalid,
        });
      } else reject({ data, status });
    })
    .catch(() => {
      reject({ data: null, status });
    });
}

function expireHandler() {
  const status: AuthTokenEnum = AppStorage.tokenStatus.get();
  return new Promise((resolve) => {
    if (status === AuthTokenEnum.refreshing) {
      const interval = setInterval(() => {
        if (AppStorage.tokenStatus.get() === AuthTokenEnum.valid) {
          clearInterval(interval);
          resolve(true);
        } else if (AppStorage.tokenStatus.get() === AuthTokenEnum.invalid) {
          clearInterval(interval);
        }
      }, 500);
    } else {
      AppStorage.tokenStatus.set(AuthTokenEnum.refreshing);
      let status: number;
      fetch(APIPath.auth.refresh, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh: AppStorage.authToken.get()?.refresh }),
      })
        .then(function (response) {
          status = response.status;
          return response.json();
        })
        .then((data: { access: string; expire_date: string }) => {
          const token = AppStorage.authToken.get();
          if (responseValidator(status) && data.access && token) {
            token.access = data.access;
            AppStorage.authToken.set(token);
            AppStorage.tokenStatus.set(AuthTokenEnum.valid);
            resolve(true);
          } else {
            __logout(update);
          }
        })
        .catch(() => __logout(update));
    }
  });
}
