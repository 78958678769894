import React, { useEffect, useRef, useState } from "react";
import { __ListBuilderProp, __ListBuilderRef } from "./listBuilder.type";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { MdClose, MdSearch, MdSort } from "react-icons/md";
import { RestAPI } from "scripts";
import { PaginationType, SelectiveItemType, ProductType } from "types";
import "./listBuilder.style.scss";
import {
  Checkbox,
  CircularProgress,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { MdMoreVert, MdDelete, MdEditNote } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ButtonComp, ModalComp } from "utilities";
/*
filter and sort
*/
const __ListBuilderComp: React.ForwardRefRenderFunction<
  __ListBuilderRef,
  __ListBuilderProp
> = (globalProps: __ListBuilderProp, ref) => {
  const [data, setData] = useState<PaginationType<any>>();
  const [isMenuOpen, setIsMenuOpen] = useState<null | {
    index: number;
    el: HTMLElement;
  }>(null);
  const [isSortMenuOpen, setIsSortMenuOpen] =
    React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const [deleteURL, setDeleteURL] = useState<string>();
  const [filters, setFilters] = useState<any>(getDefaultFilters);
  const [search, setSearch] = useState<string>();

  React.useImperativeHandle(ref, () => ({
    update: () => {
      setFilters({ ...filters, page: 1 });
    },
  }));

  function getDefaultFilters() {
    let data: any = { page: 1 };
    if (globalProps.defaultFilters) {
      data = { ...data, ...globalProps.defaultFilters };
    }
    if (globalProps.order && globalProps.order.options.length) {
      data = {
        ...data,
        [globalProps.order.key]: globalProps.order.options[0].payload,
      };
    }
    return data;
  }

  const handleCloseMenu = () => {
    setIsMenuOpen(null);
  };
  const handleCloseOrderMenu = () => {
    setIsSortMenuOpen(null);
  };

  function searchHandler() {
    if (globalProps.searchKey) {
      setFilters({ ...filters, [globalProps.searchKey]: search });
    }
  }

  function orderClickHandler(item: SelectiveItemType) {
    if (globalProps.order) {
      setFilters({
        ...filters,
        [globalProps.order.key]: item.payload,
        page: 1,
      });
    }
  }

  function EnhancedTableHead() {
    return (
      <TableHead
        sx={{
          bgcolor: (theme) => theme.palette.grey[50],
        }}
      >
        <TableRow role="checkbox">
          {globalProps.selected ? (
            <TableCell
              align={"left"}
              padding={"none"}
              style={{ padding: "16px" }}
            ></TableCell>
          ) : null}
          {globalProps.fields.map((headCell, index) => (
            <TableCell
              key={index}
              align={"left"}
              padding={"none"}
              style={{
                padding: "16px",
                width: headCell.width,
                fontWeight: "bold",
              }}
            >
              {headCell.title}
            </TableCell>
          ))}
          <TableCell
            align={"right"}
            padding={"none"}
            style={{ padding: "16px" }}
          ></TableCell>
        </TableRow>
      </TableHead>
    );
  }
  const getData = () => {
    setData(undefined);
    RestAPI.get<PaginationType<any>>(globalProps.api, {
      ...filters,
      page_size: globalProps.pageSize ? globalProps.pageSize : 10,
    })
      .then((res) => {
        setData(res.data);
        if (globalProps.onDataCountLoad)
          globalProps.onDataCountLoad(res.data.count);
      })
      .catch(() => {
        toast.error("خطایی رخ داده است. لطفا مجددا تلاش کنید.");
      });
  };
  useEffect(getData, [filters, globalProps.pageSize]);

  function deleteHandler() {
    if (deleteURL)
      return new Promise<void>((resolve, reject) => {
        RestAPI.delete(deleteURL)
          .then((res) => {
            toast.success("عملیات حذف با موفقیت انجام شد");
          })
          .catch(() => {
            toast.error("خطایی رخ داده است. لطفا مجددا تلاش کنید");
          })
          .finally(() => {
            resolve();
            setDeleteURL(undefined);
            setFilters({ ...filters, page: 1 });
          });
      });
  }

  console.log(filters);
  return (
    <div className="ens-base-list-comp">
      <ModalComp
        onClose={() => setDeleteURL(undefined)}
        title="حذف"
        display={!!deleteURL}
        ux="center"
        maxWidth="md"
        responsiveUX="bottom"
        actions={
          <>
            <ButtonComp variant="text">خیر</ButtonComp>
            <ButtonComp onClick={deleteHandler} variant="text">
              بله
            </ButtonComp>
          </>
        }
      >
        <p className="typo-p3 mb-2">آیا از حذف این مورد اطمینان دارید؟</p>
      </ModalComp>
      <Paper variant="outlined">
        {globalProps.title ||
        globalProps.searchKey ||
        globalProps.filters ||
        globalProps.order ? (
          <Toolbar>
            <div className="toolbar-right">
              {globalProps.title && (
                <p style={{ marginLeft: 16 }} className="typo-p2">
                  {globalProps.title}
                </p>
              )}
              {globalProps.searchKey && (
                <TextField
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  placeholder="جستجو..."
                  variant="outlined"
                  size="small"
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      searchHandler();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdSearch />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        style={{
                          visibility: search ? "visible" : "hidden",
                          padding: 0,
                        }}
                        onClick={() => {
                          setSearch("");
                          if (
                            globalProps.searchKey &&
                            filters[globalProps.searchKey]
                          ) {
                            setFilters({
                              ...filters,
                              [globalProps.searchKey]: search,
                            });
                          }
                        }}
                      >
                        <MdClose size={20} />
                      </IconButton>
                    ),
                  }}
                />
              )}
            </div>

            {globalProps.order && (
              <React.Fragment>
                <Tooltip title="مرتب سازی">
                  <ButtonComp
                    onClick={(event) => setIsSortMenuOpen(event.currentTarget)}
                    variant="text"
                  >
                    {
                      globalProps.order.options.find((i) =>
                        globalProps.order
                          ? i.payload === filters[globalProps.order.key]
                          : false
                      )?.title
                    }
                  </ButtonComp>
                </Tooltip>
                <Menu
                  anchorEl={isSortMenuOpen}
                  open={!!isSortMenuOpen}
                  onClose={handleCloseOrderMenu}
                >
                  {globalProps.order.options
                    .filter((i) =>
                      globalProps.order
                        ? i.payload !== filters[globalProps.order.key]
                        : true
                    )
                    .map((item) => (
                      <MenuItem
                        onClick={() => orderClickHandler(item)}
                        key={item.payload}
                      >
                        <p>{item.title}</p>
                      </MenuItem>
                    ))}
                </Menu>
              </React.Fragment>
            )}
          </Toolbar>
        ) : null}
        <TableContainer>
          <Table
            className={`${
              !data || (data && data.data.length == 0) ? "empty" : ""
            }`}
          >
            <EnhancedTableHead />
            <TableBody>
              {data &&
                (data.data.length !== 0 ? (
                  data.data.map((row: any, i: number) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => {
                          if (globalProps.click)
                            globalProps.click(
                              row,
                              globalProps.convertToSelectOption
                                ? globalProps.convertToSelectOption(row)
                                : undefined
                            );
                        }}
                        key={i}
                      >
                        {globalProps.selected ? (
                          <TableCell>
                            <Checkbox
                              color="primary"
                              checked={Boolean(
                                globalProps.selected?.find((item) =>
                                  globalProps.convertToSelectOption
                                    ? globalProps.convertToSelectOption(row)
                                        .payload === item.payload
                                    : false
                                )
                              )}
                            />
                          </TableCell>
                        ) : null}
                        {globalProps.fields.map((col, j) => (
                          <TableCell
                            align={"left"}
                            key={j}
                            style={{ width: col.width }}
                          >
                            {col.value(row)}
                          </TableCell>
                        ))}
                        <TableCell align={"right"}>
                          {(globalProps.deleteAction ||
                            globalProps.editAction) && (
                            <React.Fragment>
                              <Tooltip title="عملیات">
                                <IconButton
                                  onClick={(event) =>
                                    setIsMenuOpen({
                                      el: event.currentTarget,
                                      index: i,
                                    })
                                  }
                                >
                                  <MdMoreVert />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                anchorEl={isMenuOpen?.el}
                                open={i === isMenuOpen?.index}
                                onClose={handleCloseMenu}
                              >
                                {globalProps.editAction && (
                                  <MenuItem
                                    onClick={() => {
                                      handleCloseMenu();
                                      if (globalProps.editAction)
                                        history.push(
                                          globalProps.editAction(row)
                                        );
                                    }}
                                  >
                                    <ListItemIcon>
                                      <MdEditNote />
                                    </ListItemIcon>
                                    <ListItemText>
                                      {globalProps.onlyShow
                                        ? "نمایش جزئیات"
                                        : "ویرایش"}
                                    </ListItemText>
                                  </MenuItem>
                                )}
                                {globalProps.deleteAction && (
                                  <MenuItem
                                    onClick={() => {
                                      handleCloseMenu();
                                      if (globalProps.deleteAction)
                                        setDeleteURL(
                                          globalProps.deleteAction(row)
                                        );
                                    }}
                                  >
                                    <ListItemIcon>
                                      <MdDelete />
                                    </ListItemIcon>
                                    <ListItemText>حذف</ListItemText>
                                  </MenuItem>
                                )}
                              </Menu>
                            </React.Fragment>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <div className="no-data-box">
                    <label>داده ای موجود نیست</label>
                  </div>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {data && data.num_of_pages > 1 ? (
          <div className="pagination-footer">
            <Pagination
              shape="rounded"
              count={data.num_of_pages}
              page={filters.page}
              onChange={(e, newPage: number) =>
                setFilters({ ...filters, page: newPage })
              }
            />
          </div>
        ) : null}
      </Paper>
      {!data && (
        <div className="loading-box">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
    </div>
  );
};
export default React.forwardRef(__ListBuilderComp);
