import React, { useContext, useEffect, useState } from "react";
import { APIPath, FormsConfig, ListsConfig, RoutePath } from "data";
import { useHistory, useParams } from "react-router-dom";
import { FormBuilderComp, ListBuilderComp, PageBuilderComp } from "components";
import { GlobalContext } from "context";
import {
  AppContextActionKeyEnum,
  ProductCategoryType,
  ProductType,
} from "types";
import { MdAdd } from "react-icons/md";

const EditProductCategoryComp: React.FC = function () {
  const { id } = useParams<any>();
  const { update } = useContext(GlobalContext);
  const history = useHistory();
  const [category, setCategory] = useState<ProductCategoryType>();
  const [childCount, setChildCount] = useState<Number>(0);

  return (
    <PageBuilderComp
      title="ویرایش دسته بندی"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "دسته بندی محصولات", path: RoutePath.productCategory.index },
        { title: "ویرایش دسته بندی" },
      ]}
    >
      <FormBuilderComp
        initParser={FormsConfig.productParentCategory().initParser}
        groups={FormsConfig.productParentCategory(Boolean(childCount)).groups}
        onLoad={(e: ProductCategoryType) => {
          if (e.id !== category?.id) setCategory(e);
        }}
        submitParser={
          FormsConfig.productParentCategory(Boolean(childCount)).submitParser
        }
        api={APIPath.productCategory(id)}
        actions={[
          { title: "بازگشت", onClick: "back" },
          {
            title: "ویرایش",
            submit: {
              enable: true,
              success: "دسته بندی با موفقیت ویرایش شد",
            },
          },
        ]}
      />
      {category && !category.parent ? (
        <PageBuilderComp
          className="mt-8"
          title="دسته های فرزند این دسته"
          style={{ padding: 0, marginTop: 24 }}
          actions={[
            {
              title: "ایجاد دسته جدید",
              icon: () => <MdAdd />,
              onClick: () => {
                update({
                  key: AppContextActionKeyEnum.formDefault,
                  value: [
                    {
                      key: "parent",
                      value: { payload: category?.id, title: category?.title },
                    },
                  ],
                });
                history.push(RoutePath.productCategory.create);
              },
            },
          ]}
        >
          <ListBuilderComp
            pageSize={10}
            fields={ListsConfig.productCategory.fields}
            defaultFilters={{ parent: category.id }}
            api={APIPath.productCategory()}
            onDataCountLoad={(e) => setChildCount(e)}
            deleteAction={(e: ProductCategoryType) =>
              APIPath.productCategory(e.slug)
            }
            editAction={(e: ProductCategoryType) =>
              `${RoutePath.productCategory.edit(e.slug)}`
            }
          />
        </PageBuilderComp>
      ) : null}
      {category && category.id && category.parent ? (
        <PageBuilderComp
          className="mt-8"
          title="محصولات این دسته"
          style={{ padding: 0, marginTop: 24 }}
          actions={[
            {
              title: "ایجاد محصول جدید",
              icon: () => <MdAdd />,
              onClick: () => {
                update({
                  key: AppContextActionKeyEnum.formDefault,
                  value: [
                    {
                      key: "category",
                      value: { payload: category?.id, title: category?.title },
                    },
                  ],
                });
                history.push(RoutePath.product.create);
              },
            },
          ]}
        >
          <ListBuilderComp
            pageSize={10}
            fields={ListsConfig.product.fields}
            defaultFilters={{ category: category.id }}
            api={APIPath.product()}
            onDataCountLoad={(e) => setChildCount(e)}
            deleteAction={(e: ProductType) => APIPath.product(e.slug)}
            editAction={(e: ProductType) => `${RoutePath.product.edit(e.slug)}`}
          />
        </PageBuilderComp>
      ) : null}
    </PageBuilderComp>
  );
};
export default EditProductCategoryComp;
