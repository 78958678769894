import React, { useRef, useState } from "react";
import { __CoreEditorProps } from "./editor.type";
import __EditorToolbarComp from "./toolbar/toolbar.index";
import { __EditorToolbarRef } from "./toolbar/toolbar.type";
import SunEditor, { buttonList } from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import "suneditor/dist/css/suneditor.min.css";
import { __editorLang } from "./editor.data";

const __EditorCoreComp: React.FC<__CoreEditorProps> = (
  props: __CoreEditorProps
) => {
  const toolbarRef = useRef<__EditorToolbarRef>(null);
  const [path, setPath] = useState<any>(null);
  const ref = useRef<SunEditorCore>();
  return (
    <div className="admin-text-editor">
      <__EditorToolbarComp
        label={props.label}
        path={path}
        ref={toolbarRef}
        getEditor={() => ref.current}
      />
      <SunEditor
        lang={__editorLang}
        getSunEditorInstance={(e) => {
          ref.current = e;
        }}
        height="1200px"
        onChange={(e) => {
          if (props.onChange) props.onChange(e);
        }}
        setContents={props.value ?? ""}
        setOptions={{
          buttonList: [
            ["undo", "redo"],
            ["fullScreen"],
            // ["showBlocks"],
            ["codeView"],
            ["preview"],
            ["print"],
            ["link", "image", "video", "audio", "table"],
            ["removeFormat"],
            // ["horizontalRule"],
            ["hiliteColor"],
            ["textStyle"],
            ["subscript", "superscript"],
            ["list", "outdent", "indent"],
            ["blockquote"],
            ["paragraphStyle"],
            ["bold", "underline", "italic", "strike"],
            [
              // 'font',
              "fontSize",
              "formatBlock",
              "align",
              "fontColor",
              "lineHeight",
            ],
          ],
          rtl: true,
          alignItems: ["right", "center", "left", "justify"],
          imageGalleryUrl: "test",
        }}
      />
    </div>
  );
};

export default React.memo(__EditorCoreComp);
