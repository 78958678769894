import React from "react";
import { APIPath, ListsConfig, RoutePath } from "data";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ListBuilderComp, PageBuilderComp } from "components";
import { BlogCategoryType } from "types";

const BlogCategoryListComp: React.FC = function () {
  const history = useHistory();
  return (
    <PageBuilderComp
      actions={[
        {
          title: "ایجاد دسته بندی جدید",
          icon: () => <MdAdd />,
          onClick: () => history.push(RoutePath.blogCategory.create),
        },
      ]}
      title="دسته بندی بلاگ"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "دسته بندی بلاگ" },
      ]}
    >
      <ListBuilderComp
        pageSize={10}
        fields={ListsConfig.blogCategory.fields}
        api={APIPath.blogCategory()}
        deleteAction={(e: BlogCategoryType) => APIPath.blogCategory(e.slug)}
        editAction={(e: BlogCategoryType) =>
          RoutePath.blogCategory.edit(e.slug)
        }
      />
    </PageBuilderComp>
  );
};
export default BlogCategoryListComp;
