import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ContextProviderComp } from "context";
import "utilities/style.scss";
import AdminComp from "./admin/admin.index";

function App() {
  return (
    <ContextProviderComp>
      <Router>
        <AdminComp />
      </Router>
    </ContextProviderComp>
  );
}

export default App;
