import { ReactNode } from "react";
import { LanguageEnum } from "types";
import { __AuthStatusEnum, __LanguageEnum, __ProfileType } from "./general";

// type of the global state in context
// you can expand this one and add new fields to it
export interface __AppContextType {
  update: (...e: __AppContextActionType[]) => void;
  profile: __ProfileType | null;
  authStatus: __AuthStatusEnum;
  language?: LanguageEnum;
  formDefaultValues: { key: string; value: any }[];
}

// type of update function of global state single argument
// you can expand this one and add a {key, value} per filed you add to global state
export type __AppContextActionType =
  | {
      key: __AppContextActionKeyEnum.profile;
      value: __ProfileType | null;
    }
  | {
      key: __AppContextActionKeyEnum.authStatus;
      value: __AuthStatusEnum;
    }
  | {
      key: __AppContextActionKeyEnum.formDefault;
      value: { key: string; value: any }[];
    }
  | {
      key: __AppContextActionKeyEnum.language;
      value: LanguageEnum;
    };

// keys of the update function argument in global state
// you can expand this one and add a unique key per filed you add to global state
export enum __AppContextActionKeyEnum {
  profile = "CONTEXT_PROFILE_ACTION",
  authStatus = "CONTEXT_AUTH_STATUS_ACTION",
  formDefault = "CONTEXT_FORM_DEFAULT_VALUES",
  language = "CONTEXT_LANGUAGE_ACTION",
}

export interface FormProps {
  api: string; // https://example.com/api/v1/account/user
  fields: FormFieldProp[];
  after?: "back" | void;
}

export interface FormFieldProp {
  key: any;
  type: "text" | "number" | "dropdown" | "file" | "date" | "toggle";
  options?: [];
  default: string;
  optionLoadApi?: string;
  validation: any;
  disable?: boolean;
}

export interface listProp {
  api: string;
  fields: [];
  defaultFilters: { title: string; value: string }[];
  order: {
    key: string;
    options: { title: string; value: string }[];
  };
  filters: FormFieldProp[];
  pageSize: number;
  click: () => void | string;
}

export interface ListField {
  ascending?: string;
  descending?: string;
  title: string;
  value: (e: any) => ReactNode | string | number;
  click: () => void | string;
}
