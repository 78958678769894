import { ListBuilderProp } from "components";
import {
  BlogCategoryType,
  ProductCategoryType,
  ProductType,
  BlogPostType,
  ContactFormType,
  WorkWithFormType,
  FileAttachmentType,
  CustomerType,
  GalleryType,
} from "types";
import { __FormsConfig } from "./forms";

interface ListType {
  fields: ListBuilderProp["fields"];
  defaultFilters?: ListBuilderProp["filters"];
  order?: ListBuilderProp["order"];
  filters?: ListBuilderProp["filters"];
  searchKey?: ListBuilderProp["searchKey"];
}

const productCategory: ListType = {
  fields: [
    { title: "نام", value: (e: ProductCategoryType) => e.title, width: "60%" },
    // {
    //   title: "والد",
    //   value: (e: BlogCategoryType) => (e.parent ? e.parent.title : ""),
    //   width: "30%",
    // },
    {
      title: "تاریخ ساخت",
      value: (e: ProductCategoryType) =>
        new Date(e.created_at).toLocaleString("fa"),
      width: "40%",
    },
  ],
};

const blogCategory: ListType = {
  fields: [
    { title: "نام", value: (e: BlogCategoryType) => e.title, width: "40%" },
    {
      title: "والد",
      value: (e: BlogCategoryType) => (e.parent ? e.parent.title : ""),
      width: "30%",
    },
    {
      title: "تاریخ ساخت",
      value: (e: BlogCategoryType) =>
        new Date(e.created_at).toLocaleString("fa"),
      width: "30%",
    },
  ],
};

const product: ListType = {
  fields: [
    {
      title: "نام محصول",
      value: (e: ProductType) => e.title,
      width: "70%",
    },
    {
      title: "دسته بندی",
      value: (e: ProductType) => e.category?.title,
      width: "30%",
    },
  ],
};
const post: ListType = {
  fields: [
    {
      title: "نام پست",
      value: (e: BlogPostType) => e.title,
      width: "70%",
    },
    {
      title: "دسته بندی",
      value: (e: BlogPostType) => e.category?.title,
      width: "30%",
    },
  ],
};

const contact: ListType = {
  fields: [
    {
      title: "نام کاربر",
      value: (e: ContactFormType) => e.last_name,
      width: "50%",
    },
    {
      title: "ایمیل",
      value: (e: ContactFormType) => e.email,
      width: "50%",
    },
  ],
};

const workWithUs: ListType = {
  fields: [
    {
      title: "موضوع",
      value: (e: WorkWithFormType) => e.subject,
      width: "50%",
    },
    {
      title: "نام کاربر",
      value: (e: WorkWithFormType) => e.fullname,
      width: "50%",
    },
  ],
};

const gallery: ListType = {
  fields: [
    {
      title: "تصویر",
      value: (e: GalleryType) => (
        <a target="_blank" href={e.file.file}>
          <img
            src={e.file.file}
            style={{ width: 40, height: 40, objectFit: "cover" }}
          />
        </a>
      ),
      width: "50%",
    },
    {
      title: "اولویت نمایش",
      value: (e: GalleryType) => e.order,
      width: "50%",
    },
  ],
};

const customer: ListType = {
  fields: [
    {
      title: "عنوان",
      value: (e: CustomerType) => e.title,
      width: "50%",
    },
    {
      title: "لوگو",
      value: (e: CustomerType) => (
        <a target="_blank" href={e.image.file}>
          <img
            src={e.image.file}
            style={{ width: 40, height: 40, objectFit: "cover" }}
          />
        </a>
      ),
      width: "50%",
    },
  ],
};

export const __ListsConfig = {
  productCategory,
  product,
  blogCategory,
  post,
  contact,
  workWithUs,
  gallery,
  customer,
};
