import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React, { useState } from "react";
import { __sidebarData } from "../sidebar/sidebar.data";
import { NavLink } from "react-router-dom";

const BottomNavigationComp: React.FC = () => {
  const [index, setIndex] = useState<number>(0);
  return (
    <BottomNavigation
      className="app-main-bottom-navigation d-md-none-i"
      showLabels
      value={index}
      onChange={(event, newValue) => {
        setIndex(newValue);
      }}
    >
      {__sidebarData().map((item, i) => (
        <div className="item-group" key={i}>
          {item.children.map((link, j) => (
            <NavLink key={j} to={link.link} activeClassName="typo-primary-i">
              <BottomNavigationAction
                label={link.title}
                icon={<link.icon size={20} />}
              />
            </NavLink>
          ))}
        </div>
      ))}
    </BottomNavigation>
  );
};

export default BottomNavigationComp;
