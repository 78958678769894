import React, { useState } from "react";
import { APIPath, FormsConfig, RoutePath } from "data";
import { FormBuilderComp, PageBuilderComp } from "components";
import { useHistory } from "react-router-dom";
import { RestAPI } from "scripts";

const CreateImage: React.FC = function () {
  const history = useHistory();
  const [id, setID] = useState<number>();
  return (
    <PageBuilderComp
      title="ایجاد مشتری جدید"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "مشتری ها", path: RoutePath.customer.index },
        { title: "ایجاد مشتری جدید" },
      ]}
    >
      <FormBuilderComp
        groups={FormsConfig.customer.groups}
        submitParser={FormsConfig.customer.submitParser}
        api={APIPath.customer(id)}
        onPayloadChange={(e) => setID(e.file?.id)}
        actions={[
          { title: "بازگشت", onClick: "back" },
          {
            title: "ساخت",
            onClick: () => history.goBack(),
            submit: {
              enable: true,
              success: "مشتری با موفقیت ایجاد شد",
            },
          },
        ]}
      />
    </PageBuilderComp>
  );
};
export default CreateImage;
