import React, { useContext, useState } from "react";
import "./header.style.scss";
import { ButtonComp } from "utilities";
import { AppStorage, useEventListener } from "scripts";
import { GlobalContext } from "context";
import { __logout } from "scripts/register";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AppContextActionKeyEnum, LanguageEnum } from "types";
import { RoutePath } from "data";

const HeaderComp: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { profile, update, language } = useContext(GlobalContext);
  const langItems = [
    { title: "فارسی", payload: LanguageEnum.fa },
    { title: "انگلیسی", payload: LanguageEnum.en },
  ];

  useEventListener(
    "scroll",
    () => {
      if (window.pageYOffset > 20) setCollapsed(true);
      else setCollapsed(false);
    },
    window
  );

  let name = "";
  if (profile?.first_name?.length) name += profile.first_name;
  if (profile?.last_name?.length) name += " " + profile.last_name;
  name = name.trim();
  if (profile?.username?.length && name.length === 0) name = profile.username;

  const changeLang = (lang: LanguageEnum) => {
    if (lang !== language) {
      AppStorage.defaultLang.set(lang);
      window.location.href = RoutePath.home;
    }
  };

  return (
    <div className="app-base-header">
      <div style={{ height: collapsed ? 60 : 92 }}>
        <div className="content-keeper">
          <p>{name}</p>
          <span className="spacer"></span>
          <Select value={langItems.find((i) => i.payload === language)?.title}>
            {langItems.map((i, j) => (
              <MenuItem
                key={j}
                value={i.title}
                onClick={() => changeLang(i.payload)}
              >
                {i.title}
              </MenuItem>
            ))}
          </Select>
          <ButtonComp onClick={() => __logout(update)} variant="text">
            خروج
          </ButtonComp>
        </div>
      </div>
    </div>
  );
};

export default HeaderComp;
