export interface __PaginationType<T> {
  num_of_pages: number;
  count: number;
  data: T[];
}

export interface __ResponseType<T> {
  data: T;
  status: number;
}

export interface __ProfileType {
  id: number;
  username: string;
  phone: string;
  first_name: string;
  last_name: string;
  email: string;
}

export enum __AuthStatusEnum {
  valid = "valid",
  invalid = "invalid",
  pending = "pending",
}

export enum __LanguageEnum {
  fa = "fa",
  en = "en",
}

export interface __AuthTokenType {
  access: string;
  expire_date: string;
  refresh: string;
}

export enum __AuthTokenEnum {
  valid = "valid",
  refreshing = "refreshing",
  invalid = "invalid",
}

export interface __SelectiveItemType {
  title: string;
  payload: string | number;
}
