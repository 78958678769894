import { APIPath } from "data";
import React, { useEffect, useState } from "react";
import { AiOutlineFileText } from "react-icons/ai";
import { TiRefresh, TiTimes } from "react-icons/ti";
import { RestAPI } from "scripts";
import { FileAttachmentType, FileTypeAttachmentEnum } from "types";
import { ButtonComp, IconButtonComp } from "utilities";
import "./inprogressCard.style.scss";

const InprogressCard: React.FC<{
  file: File;
  onEnd: (e?: FileAttachmentType) => void;
}> = (props: { file: File; onEnd: (e?: FileAttachmentType) => void }) => {
  const isImage = props.file.type.includes("image");
  const [progress, setProgress] = useState<number>(100);
  const [failed, setFailed] = useState<boolean>(false);
  useEffect(onUpload, [props.file]);
  function onUpload() {
    setFailed(false);
    const data = new FormData();
    data.append("file", props.file);
    data.append("alt", props.file.name);
    data.append("type", FileTypeAttachmentEnum.image.toString());
    RestAPI.upload<FileAttachmentType>(APIPath.attachment(), data, (size) => {
      const progress = size / props.file.size;
      setProgress((progress < 0 ? 0 : progress) * 100);
    })
      .then((res) => props.onEnd(res.data))
      .catch(() => {
        console.log("ffffffffffffffail");
        setFailed(true);
      });
  }
  return (
    <div className="in-progress-card">
      {isImage ? (
        <img src={URL.createObjectURL(props.file)} />
      ) : (
        <div className="others">
          <AiOutlineFileText size={32} className="typo-gray-3 mb-2" />
          <p className="typo-p4 typo-gray-3">{props.file.name}</p>
        </div>
      )}
      {failed ? (
        <div className="failed">
          <IconButtonComp onClick={onUpload} color="error">
            <TiRefresh />
          </IconButtonComp>
          <IconButtonComp onClick={() => props.onEnd()} color="error">
            <TiTimes />
          </IconButtonComp>
        </div>
      ) : (
        <div style={{ width: 100 - progress + "%" }} className="progress"></div>
      )}
    </div>
  );
};

export default InprogressCard;
