import React from "react";
import { APIPath, ListsConfig, RoutePath } from "data";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ListBuilderComp, PageBuilderComp } from "components";
import { BlogPostType } from "types";

const ListPost: React.FC = function () {
  const history = useHistory();
  return (
    <PageBuilderComp
      actions={[
        {
          title: "ایجاد پست جدید",
          icon: () => <MdAdd />,
          onClick: () => history.push(RoutePath.blogPost.create),
        },
      ]}
      title="پست ها"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "پست ها" },
      ]}
    >
      <ListBuilderComp
        pageSize={10}
        fields={ListsConfig.post.fields}
        api={APIPath.post()}
        deleteAction={(e: BlogPostType) => APIPath.post(e.slug)}
        editAction={(e: BlogPostType) => `${RoutePath.blogPost.edit(e.slug)}`}
      />
    </PageBuilderComp>
  );
};
export default ListPost;
