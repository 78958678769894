import { RoutePath } from "data";
import {
  BiSolidBasket,
  BiSolidCategoryAlt,
  BiSolidFile,
  BiSolidPhone,
  BiSolidNetworkChart,
  BiImages,
  BiSolidUserAccount,
} from "react-icons/bi";

export const __sidebarData = () => [
  {
    title: "محصولات",
    children: [
      {
        icon: BiSolidCategoryAlt,
        title: "دسته بندی",
        link: RoutePath.productCategory.index,
      },
      {
        icon: BiSolidBasket,
        title: "محصولات",
        link: RoutePath.product.index,
      },
    ],
  },
  {
    title: "وبلاگ",
    children: [
      {
        icon: BiSolidCategoryAlt,
        title: "دسته بندی",
        link: RoutePath.blogCategory.index,
      },
      {
        icon: BiSolidFile,
        title: "پست ها",
        link: RoutePath.blogPost.index,
      },
    ],
  },
  {
    title: "عمومی",
    children: [
      {
        icon: BiSolidUserAccount,
        title: "مشتری ها",
        link: RoutePath.customer.index,
      },
      {
        icon: BiImages,
        title: "گالری",
        link: RoutePath.gallery.index,
      },
    ],
  },
  {
    title: "سایر",
    children: [
      {
        icon: BiSolidPhone,
        title: "درخواست های تماس با ما",
        link: RoutePath.contact.index,
      },
      {
        icon: BiSolidNetworkChart,
        title: "درخواست های همکاری با ما",
        link: RoutePath.work.index,
      },
    ],
  },
];
