import React from "react";
import { APIPath, FormsConfig, RoutePath } from "data";
import { useParams } from "react-router-dom";
import { FormBuilderComp, PageBuilderComp } from "components";

const DetailWorkWithForm: React.FC = function () {
  const { id } = useParams<any>();

  return (
    <PageBuilderComp
      title="جزئیات فرم همکاری"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "فرم های همکاری", path: RoutePath.work.index },
        { title: "جزئیات فرم همکاری" },
      ]}
    >
      <FormBuilderComp
        {...FormsConfig.workWithUsForm}
        api={APIPath.workWith(id)}
        actions={[{ title: "بازگشت", onClick: "back" }]}
      />
    </PageBuilderComp>
  );
};
export default DetailWorkWithForm;
