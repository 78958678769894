import React, { useContext } from 'react';
import './layout.style.scss';
import SideBarComp from './sidebar/sidebar.index';
import HeaderComp from './header/header.index';
import BottomNavigationComp from './bottomNav/bottomNav.index';
import { GlobalContext } from 'context';

const LayoutComp: React.FC<React.PropsWithChildren> = (props: React.PropsWithChildren) => {
    return (
        <div className="app-base-layout">
            <SideBarComp />
            <div>
                <HeaderComp />
                <main>{props.children}</main>
                <BottomNavigationComp />
            </div>
        </div>
    );
};

export default LayoutComp;
