import { GlobalContext } from "context";
import { RoutePath } from "data";
import rtlPlugin from "stylis-plugin-rtl";
import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthStatusEnum } from "types";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { createTheme, ThemeProvider } from "@mui/material";
import SignInComp from "./signIn/signIn.index";
import LayoutComp from "../components/layout/layout.index";
import { __registerCheck } from "scripts/register";
import ProductComp from "./product/product.index";
import PostComp from "./post/post.index";
import BlogCategoryComp from "./blogCategory/blogCategory.index";
import ProductCategoryComp from "./productCategory/productCategory.index";
import ContactComp from "./contact/contact.index";
import WorkWithComp from "./workWithUs/workWithUs.index";
import GalleryComp from "./gallery/gallery.index";
import CustomerComp from "./customer/customer.index";

const cacheRTL = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const AdminComp: React.FC = function () {
  const { update, authStatus } = useContext(GlobalContext);
  useEffect(() => {
    __registerCheck(update);
  }, []);
  const theme = createTheme({
    direction: "rtl",
    palette: {
      primary: {
        light: "#8267cf",
        main: "#6342C3",
        dark: "#452e88",
        contrastText: "#fff",
      },
      secondary: {
        light: "#d561b5",
        main: "#CB3AA3",
        dark: "#8e2872",
        contrastText: "#fff",
      },
    },
  });

  return (
    <CacheProvider value={cacheRTL}>
      <ThemeProvider theme={theme}>
        <Router>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {authStatus == AuthStatusEnum.valid ? (
            <LayoutComp>
              <Switch>
                <Route path={RoutePath.blogPost.index}>
                  <PostComp />
                </Route>
                <Route path={RoutePath.blogCategory.index}>
                  <BlogCategoryComp />
                </Route>
                <Route path={RoutePath.productCategory.index}>
                  <ProductCategoryComp />
                </Route>
                <Route path={RoutePath.product.index}>
                  <ProductComp />
                </Route>
                <Route path={RoutePath.contact.index}>
                  <ContactComp />
                </Route>
                <Route path={RoutePath.work.index}>
                  <WorkWithComp />
                </Route>
                <Route path={RoutePath.gallery.index}>
                  <GalleryComp />
                </Route>
                <Route path={RoutePath.customer.index}>
                  <CustomerComp />
                </Route>
                <Route path={"*"}>
                  <h2 style={{ marginRight: 16 }}>به پنل مدیریت خوش آمدید.</h2>
                </Route>
              </Switch>
            </LayoutComp>
          ) : (
            authStatus == AuthStatusEnum.invalid && <SignInComp />
          )}
        </Router>
      </ThemeProvider>
    </CacheProvider>
  );
};
export default AdminComp;
