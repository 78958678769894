import { RoutePath } from "data";
import React from "react";
import { Route, Switch } from "react-router-dom";
import CreateCustomer from "./create/create.index";
import EditCustomer from "./edit/edit.index";
import ListCustomer from "./list/list.index";

const CustomerComp: React.FC = function () {
  return (
    <Switch>
      <Route path={RoutePath.customer.create}>
        <CreateCustomer />
      </Route>
      <Route path={RoutePath.customer.edit()}>
        <EditCustomer />
      </Route>
      <Route path={"*"}>
        <ListCustomer />
      </Route>
    </Switch>
  );
};
export default CustomerComp;
