import React, { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { __SingleAttachProps } from "./singleFileAttach.type";
import { FileAttachmentType } from "types";
import "./singleFileAttach.style.scss";
import AttachedCardComp from "./attachedCard/attachedCard.index";
import InprogressCard from "./inprogressCard/inprogressCard.index";
import { RiUploadCloud2Line } from "react-icons/ri";
import { FormHelperText } from "@mui/material";
import { IconButtonComp } from "utilities";
import { BsCardImage } from "react-icons/bs";
import __GalleryToolbarOptionComp from "../gallery/gallery.index";

const __SingleFileAttachComp: React.FC<__SingleAttachProps> = (
  props: __SingleAttachProps
) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [hover, setHover] = useState<boolean>(false); // to show drop file cover on drop file
  const [inprogress, setInprogress] = useState<File>();
  const [gallery, setGallery] = useState<"image" | "video" | "*">();

  function onChangeHandler() {
    // on select file from local
    if (fileInputRef.current?.files?.length) {
      const file: File = fileInputRef.current.files[0];
      setInprogress(file);
    }
  }

  function onDropHandler(e: React.DragEvent<HTMLDivElement>) {
    // on image drop, file will upload
    e.preventDefault();
    e.stopPropagation();
    setHover(false);
    if (e.dataTransfer.files.length && !props.disabled)
      setInprogress(e.dataTransfer.files[0]);
  }

  let accept: "image" | "video" | "*" = "*";
  if (props.accept.includes("image")) accept = "image";
  else if (props.accept.includes("video")) accept = "video";

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => {
        const value: FileAttachmentType | undefined = field.value
          ? field.value
          : undefined;
        return (
          <div
            className={`ens-single-file-attachment ${
              props.className ? props.className : ""
            } ${Boolean(fieldState.error?.message?.length) ? "error" : ""}`}
          >
            <__GalleryToolbarOptionComp
              display={gallery}
              onClose={() => setGallery(undefined)}
              onSubmit={(e) => {
                field.onChange(e);
                setGallery(undefined);
              }}
            />
            <div
              onDrop={onDropHandler}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!props.disabled) setHover(true); // set box status to wait for drop on drag file
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setHover(false); // set box status to normal on drag leave
              }}
            >
              <input
                key={
                  (inprogress?.lastModified ?? "").toString() +
                  (inprogress?.name ?? "").toString() +
                  (inprogress?.type ?? "").toString()
                }
                accept={props.accept}
                multiple={true}
                onChange={() => onChangeHandler()}
                ref={fileInputRef}
                className="d-none"
                type="file"
                name="file"
              />
              <div className="title">
                <p className="typo-p3 typo-gray-2">
                  {props.label ? props.label : ""}
                </p>
                {/* <IconButtonComp onClick={() => setGallery(accept)}>
                  <BsCardImage fontSize={18} />
                </IconButtonComp> */}
              </div>
              <div className="files-list">
                <div>
                  {value ? (
                    <AttachedCardComp
                      onRemove={
                        !props.disabled
                          ? () => {
                              field.onChange(null);
                            }
                          : undefined
                      }
                      data={value}
                      key={value.id}
                    />
                  ) : null}
                  {inprogress ? (
                    <InprogressCard
                      onEnd={(e) => {
                        if (e) field.onChange(e);
                        setInprogress(undefined);
                      }}
                      file={inprogress}
                    />
                  ) : null}
                  {!inprogress && !value ? (
                    <div
                      onClick={() => fileInputRef.current?.click()}
                      className="empty-state pointer"
                    >
                      <RiUploadCloud2Line
                        size={48}
                        className="mb-2 typo-gray-3"
                      />
                      <p className="typo-p4 typo-gray-3">
                        برای انتخاب فایل کلیک کنید و یا فایل ها را بکشید و رها
                        کنید
                      </p>
                    </div>
                  ) : null}
                  {hover ? (
                    <div className="hover typo-p3 typo-gray-3">
                      برای آپلود، فایل ها را اینجا رها کنید
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {Boolean(fieldState.error?.message?.length) || props.hint ? (
              <FormHelperText
                error={Boolean(fieldState.error?.message?.length)}
              >
                {fieldState.error?.message?.length
                  ? fieldState.error.message
                  : props.hint}
              </FormHelperText>
            ) : null}
          </div>
        );
      }}
    />
  );
};

export default __SingleFileAttachComp;
