import { ReactNode } from "react";
import { Control, FieldValues } from "react-hook-form";

export interface __InputProps {
  control: Control<FieldValues, object>;
  name: string;
  label?: string;
  hint?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  variant?: "outlined" | "filled" | "standard";
  required?: boolean;
}

export interface __LocalCompRefType {
  open: () => void;
}

export interface __LocalCompProp {
  onChange: (
    serialize: string,
    html: string,
    maxWidth: number,
    backgroundColor: string
  ) => void;
  html?: string;
  serialize?: string;
  maxWidth?: number;
  backgroundColor?: string;
}

export enum __EditorKeyEnum {
  init = "ENS_PAGE_EDITOR_INIT",
  submit = "ENS_PAGE_EDITOR_SUBMIT",
  load = "ENS_PAGE_EDITOR_LOAD",
}
export interface __EditorSubmitType {
  key: __EditorKeyEnum.submit;
  serialize: string;
  html: string;
  maxWidth?: number;
  backgroundColor?: string;
}

export interface __EditorInitType {
  key: __EditorKeyEnum.init;
  serialize?: string;
  maxWidth?: number;
  backgroundColor?: string;
}

export interface __EditorLoadType {
  key: __EditorKeyEnum.load;
}
