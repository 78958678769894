const extractURLParams = function (url: string) {
  const params: any = {};
  const parser = document.createElement("a");
  parser.href = url;
  const query = parser.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};

function IsJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const prsNum2Eng = function (str: string) {
  // convert ۰-۹ numbers to 0-9 in a text
  const persianNumbers = [
      /۰/g,
      /۱/g,
      /۲/g,
      /۳/g,
      /۴/g,
      /۵/g,
      /۶/g,
      /۷/g,
      /۸/g,
      /۹/g,
    ],
    arabicNumbers = [
      /٠/g,
      /١/g,
      /٢/g,
      /٣/g,
      /٤/g,
      /٥/g,
      /٦/g,
      /٧/g,
      /٨/g,
      /٩/g,
    ];
  if (typeof str === "string") {
    for (let i = 0; i < 10; i++) {
      str = str
        .replace(persianNumbers[i], i.toString())
        .replace(arabicNumbers[i], i.toString());
    }
  }
  return str;
};

const safe2HTML = (text?: string) => {
  // convert backend safe html to html
  let e = text;
  const textarea = document.createElement("textarea");
  textarea.style.cssText = "opacity: 0; z-index:-10000;";
  document.body.appendChild(textarea);
  if (e && e.length > 0)
    while (e.includes("&amp;") || e.includes("&lt;") || e.includes("&quot;")) {
      textarea.innerHTML = e;
      e = textarea.value;
    }
  textarea.remove();
  return e ? e : "";
};

export const __StringTools = {
  safe2HTML,
  extractURLParams,
  IsJsonString,
  prsNum2Eng,
};
