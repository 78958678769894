import React from "react";
import { APIPath, ListsConfig, RoutePath } from "data";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ListBuilderComp, PageBuilderComp } from "components";
import { ProductType } from "types";

const ListProduct: React.FC = function () {
  const history = useHistory();
  return (
    <PageBuilderComp
      actions={[
        {
          title: "ایجاد محصول جدید",
          icon: () => <MdAdd />,
          onClick: () => history.push(RoutePath.product.create),
        },
      ]}
      title="محصولات"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "محصولات" },
      ]}
    >
      <ListBuilderComp
        pageSize={10}
        fields={ListsConfig.product.fields}
        api={APIPath.product()}
        deleteAction={(e: ProductType) => APIPath.product(e.slug)}
        editAction={(e: ProductType) => `${RoutePath.product.edit(e.slug)}`}
      />
    </PageBuilderComp>
  );
};
export default ListProduct;
