import React from "react";
import { APIPath, ListsConfig, RoutePath } from "data";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ListBuilderComp, PageBuilderComp } from "components";
import { FileAttachmentType } from "types";
import { Alert } from "@mui/material";

const ListImage: React.FC = function () {
  const history = useHistory();

  return (
    <PageBuilderComp
      actions={[
        {
          title: "ایجاد تصویر جدید",
          icon: () => <MdAdd />,
          onClick: () => history.push(RoutePath.gallery.create),
        },
      ]}
      title="گالری"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "گالری" },
      ]}
    >
      <Alert className="mb-2" severity="info">
        لطفا توجه داشته باشید که تعداد تصاویری که در بخش گالری صفحه اصلی نمایش
        داده میشود ۱۱ عدد است. افزودن داده بیشتر تغییری در آن ایجاد نمیکند
      </Alert>
      <Alert className="mb-2" severity="info">
        اولویت نمایش مقداری عددی است که هرچه بالاتر باشد آن تصویر در ابتدای
        انیمیشن نمایش داده میشود. با دقت به انیمیشن صفحه اصلی میتوانید بهترین
        عدد را برای تصاویر مودرد نظر خود بیابید
      </Alert>

      <ListBuilderComp
        pageSize={20}
        fields={ListsConfig.gallery.fields}
        api={APIPath.gallery()}
        deleteAction={(e: FileAttachmentType) => APIPath.gallery(e.id)}
        editAction={(e: FileAttachmentType) =>
          `${RoutePath.gallery.edit(e.id)}`
        }
      />
    </PageBuilderComp>
  );
};
export default ListImage;
