import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { __InputProps } from "./price.type";
import "./price.style.scss";

const __PriceComp: React.FC<__InputProps> = (props: __InputProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => (
        <TextField
          fullWidth={true}
          className={`app-price-utility ${
            props.className ? props.className : ""
          }`}
          type="text"
          label={props.label}
          placeholder={props.placeholder}
          variant={props.variant ? props.variant : "outlined"}
          disabled={Boolean(props.disabled)}
          error={Boolean(fieldState.error)}
          helperText={
            fieldState.error && fieldState.error.message
              ? fieldState.error.message
              : props.hint
          }
          required={props.required}
          InputProps={{
            autoComplete: "new-password",
            startAdornment: props.startIcon ? (
              <InputAdornment position="start">
                {props.startIcon}
              </InputAdornment>
            ) : undefined,
            endAdornment: props.endIcon ? (
              <InputAdornment position="end">{props.endIcon}</InputAdornment>
            ) : undefined,
          }}
          {...field}
          onChange={(e) =>
            field.onChange(Number(e.target.value.replace(/,/g, "")))
          }
          value={
            !Number.isNaN(Number(field.value))
              ? Number(field.value).toLocaleString()
              : ""
          }
        />
      )}
    />
  );
};

export default __PriceComp;
