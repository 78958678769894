import React, { useContext, useRef, useState } from "react";
import { APIPath, FormsConfig, ListsConfig, RoutePath } from "data";
import { useHistory, useParams } from "react-router-dom";
import { FormBuilderComp, ListBuilderComp, PageBuilderComp } from "components";
import {
  AppContextActionKeyEnum,
  ProductCategoryType,
  ProductType,
  TechnicalSpecificationType,
} from "types";
import { MdAdd } from "react-icons/md";
import { GlobalContext } from "context";
import { toast } from "react-toastify";
import { AiOutlineImport } from "react-icons/ai";

const EditProduct: React.FC = function () {
  const { id } = useParams<any>();
  const [product, setProduct] = useState<ProductType>();
  const history = useHistory();
  const ref = useRef<HTMLInputElement>(null);
  const { update } = useContext(GlobalContext);

  function loadData(fileList: FileList | null) {
    if (!fileList?.length) return;
    const file = fileList[0];
    file.text().then((res) => {
      const result: TechnicalSpecificationType[] = [];
      res
        .split("\n")
        .filter((i) => i.length > 0)
        .map((i, index) => {
          if (index > 0) {
            const row = i.split(",");
            console.log(row);
            result.push({
              serial_number: row[0],
              parameters: row[1],
              unit: row[2],
              standard: row[3],
            });
          }
        });
      if (result.length)
        update({
          key: AppContextActionKeyEnum.formDefault,
          value: [
            {
              key: "technical_specification",
              value: result,
            },
          ],
        });
      else toast.error("فایل اتخابی فاقد محتواست");
    });
  }

  return (
    <PageBuilderComp
      title="ویرایش محصول"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "محصولات", path: RoutePath.product.index },
        { title: "ویرایش محصول" },
      ]}
      actions={[
        {
          title: "وارد کردن مشخصه های فنی از طریق اکسل",
          icon: () => <AiOutlineImport />,
          onClick: () => ref.current?.click(),
        },
      ]}
    >
      <input
        ref={ref}
        onChange={(e) => loadData(e.target.files)}
        type="file"
        accept="text/csv"
        className="d-none"
      />
      <FormBuilderComp
        {...FormsConfig.product}
        api={APIPath.product(id)}
        onLoad={(e: ProductType) => setProduct(e)}
        actions={[
          { title: "بازگشت", onClick: "back" },
          {
            title: "ویرایش",
            submit: {
              enable: true,
              success: "محصول با موفقیت ویرایش شد",
            },
          },
        ]}
      />
      {/* <PageBuilderComp
        className="mt-8"
        title="دسته های این محصول"
        style={{ padding: 0 }}
        actions={[
          {
            title: "ایجاد دسته جدید",
            icon: () => <MdAdd />,
            onClick: () => {
              update({
                key: AppContextActionKeyEnum.formDefault,
                value: [
                  {
                    key: "product",
                    value: { payload: product?.id, title: product?.title },
                  },
                ],
              });
              history.push(RoutePath.product.create);
            },
          },
        ]}
      >
        <ListBuilderComp
          pageSize={10}
          fields={ListsConfig.productCategory.fields}
          defaultFilters={{ product: id }}
          api={APIPath.productCategory()}
          deleteAction={(e: ProductCategoryType) =>
            APIPath.productCategory(e.slug)
          }
          editAction={(e: ProductCategoryType) =>
            `${RoutePath.product.edit(e.slug)}`
          }
        />
      </PageBuilderComp> */}
    </PageBuilderComp>
  );
};
export default EditProduct;
