import {
  Box,
  Chip,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  FormControl,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { __MultiAutoCompleteProps } from "./multiAutoComplete.type";
import "./multiAutoComplete.style.scss";
import { ButtonComp, IconButtonComp, ModalComp } from "utilities";
import { FormBuilderComp, ListBuilderComp } from "components";
import { SelectiveItemType } from "types";
import { TbEdit, TbPlus, TbX } from "react-icons/tb";

const __MultiAutoCompleteComp: React.FC<__MultiAutoCompleteProps> = (
  props: __MultiAutoCompleteProps
) => {
  const [selected, setSelected] = useState<SelectiveItemType[]>();
  const [form, setForm] = useState<string | number>();
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => {
        const values: SelectiveItemType[] =
          field.value && field.value.length ? field.value : [];
        return (
          <FormControl
            className={
              "ens-multi-auto-complete-utility " +
              (props.className ? props.className : "")
            }
            fullWidth
            error={Boolean(fieldState.error?.message?.length)}
          >
            <ModalComp
              maxWidth="md"
              display={Boolean(selected) && form === undefined}
              onClose={() => setSelected(undefined)}
              title={props.label}
              actions={
                <>
                  <ButtonComp
                    onClick={() => setSelected(undefined)}
                    variant="text"
                  >
                    انصراف
                  </ButtonComp>
                  <ButtonComp onClick={() => setForm(0)} variant="text">
                    ساخت داده جدید
                  </ButtonComp>
                  <ButtonComp
                    onClick={() => {
                      field.onChange(selected);
                      setSelected(undefined);
                    }}
                    variant="text"
                  >
                    انتخاب {selected?.length} مورد
                  </ButtonComp>
                </>
              }
            >
              <ListBuilderComp
                pageSize={10}
                fields={props.listProps.fields}
                api={props.api()}
                defaultFilters={props.listProps.defaultFilters}
                convertToSelectOption={props.listProps.convertToSelectOption}
                selected={selected}
                click={(item, converted) => {
                  if (converted) {
                    if (
                      selected?.find((i) => i.payload === converted.payload)
                    ) {
                      setSelected([
                        ...selected.filter(
                          (i) => i.payload !== converted.payload
                        ),
                      ]);
                    } else
                      setSelected([...(selected ? selected : []), converted]);
                  }
                }}
                searchKey={props.listProps.searchKey}
                order={props.listProps.order}
              />
            </ModalComp>
            {props.formProps ? (
              <ModalComp
                maxWidth="md"
                display={form !== undefined}
                onClose={() => setForm(undefined)}
                title={props.label}
              >
                <FormBuilderComp
                  initParser={
                    form === 0 ? undefined : props.formProps.initParser
                  }
                  submitParser={props.formProps.submitParser}
                  groups={props.formProps.groups()}
                  api={props.api(form === 0 ? undefined : form)}
                  actions={[
                    {
                      title: "انصراف",
                      variant: "text",
                      onClick: () => setForm(undefined),
                    },
                    {
                      title: form === 0 ? "ساخت" : "ویرایش",
                      variant: "text",
                      onClick: (e, res) => {
                        const data = props.listProps.convertToSelectOption(res);
                        const newData = [...values];
                        const index = values.findIndex(
                          (i) => i.payload === data.payload
                        );
                        if (index >= 0) newData[index].title = data.title;
                        else newData.push(data);
                        field.onChange(newData);
                        setForm(undefined);
                      },
                      submit: {
                        enable: true,
                        success:
                          form === 0
                            ? "ساخت داده با موفقیت انجام شد"
                            : "ویرایش داده با موفقیت انجام شد",
                      },
                    },
                  ]}
                />
              </ModalComp>
            ) : null}
            <InputLabel>{props.label}</InputLabel>
            <Select
              multiple
              onOpen={() => setSelected(values)}
              value={values.length ? [""] : []}
              open={false}
              placeholder={props.placeholder}
              input={
                <OutlinedInput
                  endAdornment={
                    <InputAdornment className="ml-2" position="end">
                      <IconButtonComp onClick={() => setForm(0)}>
                        <TbPlus />
                      </IconButtonComp>
                    </InputAdornment>
                  }
                  label={props.label}
                />
              }
              renderValue={() => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {values.map((item: SelectiveItemType) => (
                    <Chip
                      onDelete={() => setForm(item.payload)}
                      deleteIcon={
                        <>
                          <IconButtonComp
                            size="small"
                            onClick={() => setForm(item.payload)}
                          >
                            <TbEdit />
                          </IconButtonComp>
                          <IconButtonComp
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              field.onChange(
                                values.filter((i) => i.payload !== item.payload)
                              );
                            }}
                          >
                            <TbX />
                          </IconButtonComp>
                        </>
                      }
                      key={item.payload}
                      className="ml-1"
                      label={item.title}
                    />
                  ))}
                </Box>
              )}
            >
              <MenuItem disabled value=""></MenuItem>
            </Select>
            {Boolean(fieldState.error?.message?.length) || props.hint ? (
              <FormHelperText
                error={Boolean(fieldState.error?.message?.length)}
              >
                {fieldState.error?.message?.length
                  ? fieldState.error.message
                  : props.hint}
              </FormHelperText>
            ) : null}
          </FormControl>
        );
      }}
    />
  );
};

export default __MultiAutoCompleteComp;
