import {
  Box,
  Chip,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  FormControl,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { __MultiAutoCompleteProps } from "./singleAutoComplete.type";
import "./singleAutoComplete.style.scss";
import { ButtonComp, IconButtonComp, ModalComp } from "utilities";
import { FormBuilderComp, ListBuilderComp } from "components";
import { SelectiveItemType } from "types";
import { TbEdit, TbPlus, TbX } from "react-icons/tb";

const __SingleAutoCompleteComp: React.FC<__MultiAutoCompleteProps> = (
  props: __MultiAutoCompleteProps
) => {
  const [display, setDisplay] = useState<boolean>(false);
  const [form, setForm] = useState<string | 0>();
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => {
        const value: SelectiveItemType | undefined = field.value
          ? field.value
          : undefined;
        return (
          <FormControl
            className={
              "ens-single-auto-complete-utility " +
              (props.className ? props.className : "")
            }
            fullWidth
            error={Boolean(fieldState.error?.message?.length)}
          >
            <ModalComp
              maxWidth="md"
              display={display && form === undefined}
              onClose={() => setDisplay(false)}
              title={props.label}
              actions={
                <>
                  <ButtonComp onClick={() => setDisplay(false)} variant="text">
                    انصراف
                  </ButtonComp>
                  <ButtonComp onClick={() => setForm(0)} variant="text">
                    ساخت داده جدید
                  </ButtonComp>
                </>
              }
            >
              <ListBuilderComp
                pageSize={10}
                fields={props.listProps.fields}
                api={props.api()}
                defaultFilters={props.listProps.defaultFilters}
                convertToSelectOption={props.listProps.convertToSelectOption}
                click={(item, converted) => {
                  field.onChange(converted);
                  setDisplay(false);
                }}
                searchKey={props.listProps.searchKey}
                order={props.listProps.order}
              />
            </ModalComp>
            {props.formProps ? (
              <ModalComp
                maxWidth="md"
                display={form !== undefined}
                onClose={() => setForm(undefined)}
                title={props.label}
              >
                <FormBuilderComp
                  initParser={
                    form === 0 ? undefined : props.formProps.initParser
                  }
                  submitParser={props.formProps.submitParser}
                  groups={props.formProps.groups()}
                  api={props.api(form === 0 ? undefined : form)}
                  actions={[
                    {
                      title: "انصراف",
                      variant: "text",
                      onClick: () => setForm(undefined),
                    },
                    {
                      title: form === 0 ? "ساخت" : "ویرایش",
                      onClick: (e, res) => {
                        const data = props.listProps.convertToSelectOption(res);
                        field.onChange(data);
                        setForm(undefined);
                      },
                      variant: "text",
                      submit: {
                        enable: true,
                        success:
                          form === 0
                            ? "ساخت داده با موفقیت انجام شد"
                            : "ویرایش داده با موفقیت انجام شد",
                      },
                    },
                  ]}
                />
              </ModalComp>
            ) : null}
            <InputLabel>{props.label}</InputLabel>
            <Select
              multiple
              onOpen={() => {
                if (!props.disabled) setDisplay(true);
              }}
              value={value ? [""] : []}
              open={false}
              placeholder={props.placeholder}
              input={
                <OutlinedInput
                  endAdornment={
                    <InputAdornment className="ml-2" position="end">
                      {value && !props.disabled ? (
                        <IconButtonComp onClick={() => field.onChange(null)}>
                          <TbX />
                        </IconButtonComp>
                      ) : null}
                      {value ? (
                        <IconButtonComp
                          onClick={() => setForm(value.payload.toString())}
                        >
                          <TbEdit />
                        </IconButtonComp>
                      ) : null}
                      {!props.disabled && !props.formProps?.disableCreate ? (
                        <IconButtonComp onClick={() => setForm(0)}>
                          <TbPlus />
                        </IconButtonComp>
                      ) : null}
                    </InputAdornment>
                  }
                  disabled={props.disabled}
                  label={props.label}
                />
              }
              renderValue={() => value?.title}
            >
              <MenuItem disabled value=""></MenuItem>
            </Select>
            {Boolean(fieldState.error?.message?.length) || props.hint ? (
              <FormHelperText
                error={Boolean(fieldState.error?.message?.length)}
              >
                {fieldState.error?.message?.length
                  ? fieldState.error.message
                  : props.hint}
              </FormHelperText>
            ) : null}
          </FormControl>
        );
      }}
    />
  );
};

export default __SingleAutoCompleteComp;
