import React from "react";
import { APIPath, FormsConfig, RoutePath } from "data";
import { FormBuilderComp, PageBuilderComp } from "components";
import { useHistory } from "react-router-dom";

const CreateBlogCategoryComp: React.FC = function () {
  const history = useHistory();
  return (
    <PageBuilderComp
      title="ایجاد دسته بندی جدید"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "دسته بندی وبلاگ", path: RoutePath.blogCategory.index },
        { title: "ایجاد دسته بندی جدید" },
      ]}
    >
      <FormBuilderComp
        groups={FormsConfig.blogCategory.groups}
        submitParser={FormsConfig.blogCategory.submitParser}
        api={APIPath.blogCategory()}
        actions={[
          { title: "بازگشت", onClick: "back" },
          {
            title: "ساخت",
            onClick: () => history.goBack(),
            submit: {
              enable: true,
              success: "دسته بندی با موفقیت ایجاد شد",
            },
          },
        ]}
      />
    </PageBuilderComp>
  );
};
export default CreateBlogCategoryComp;
