import { Badge, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import { Link } from "react-router-dom";
import { __IconButtonProps } from "./iconButton.type";

const __IconButtonComp: React.FC<PropsWithChildren<__IconButtonProps>> = (
  props: PropsWithChildren<__IconButtonProps>
) => {
  const [loading, setLoading] = useState<boolean>(false);

  let loadingSize = 20;
  if (props.size === "small") loadingSize = 14;
  else if (props.size === "large") loadingSize = 24;

  function onClickHandler(
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ) {
    if (props.disabled || loading) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (props.onClick) {
      const promise = props.onClick(e);
      // if onClick function return promise we should render loading, because the task is async
      if (promise) {
        setLoading(true);
        promise.then(() => {
          setLoading(false);
        });
      }
    }
  }

  const Icon = (
    <IconButton
      style={{
        height: "fit-content",
        ...(!props.href && !props.badge ? props.style : {}),
      }}
      color={props.color}
      className={
        props.badge === undefined && !props.href ? props.className : ""
      }
      size={props.size}
      onClick={!props.href ? onClickHandler : undefined}
      onMouseDown={props.onMouseDown}
    >
      {loading ? (
        <CircularProgress color="inherit" size={loadingSize} />
      ) : (
        props.children
      )}
    </IconButton>
  );

  const Main = (
    <>
      {props.badge !== undefined ? (
        <Badge
          style={props.href ? undefined : props.style}
          className={props.href ? "" : props.className}
          color={props.badge.color}
          badgeContent={props.badge.content}
          variant={props.badge.content ? "standard" : "dot"}
          invisible={props.badge.invisible}
        >
          {Icon}
        </Badge>
      ) : (
        Icon
      )}
    </>
  );

  const final = props.tooltip?.length ? (
    <Tooltip title={props.tooltip}>{Main}</Tooltip>
  ) : (
    Main
  );

  if (!props.href) return final;
  else
    return (
      <Link
        style={props.href ? props.style : undefined}
        className={props.className}
        onClick={onClickHandler}
        to={{ pathname: props.href }}
      >
        {final}
      </Link>
    );
};

export default __IconButtonComp;
