import React from "react";
import { APIPath, FormsConfig, RoutePath } from "data";
import { useParams } from "react-router-dom";
import { FormBuilderComp, PageBuilderComp } from "components";

const EditCustomer: React.FC = function () {
  const { id } = useParams<any>();

  return (
    <PageBuilderComp
      title="ویرایش مشتری"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "مشتری ها", path: RoutePath.customer.index },
        { title: "ویرایش مشتری" },
      ]}
    >
      <FormBuilderComp
        {...FormsConfig.customer}
        api={APIPath.customer(id)}
        initParser={FormsConfig.customer.initParser}
        submitParser={FormsConfig.customer.submitParser}
        actions={[
          { title: "بازگشت", onClick: "back" },
          {
            title: "ویرایش",
            submit: {
              enable: true,
              success: "مشتری با موفقیت ویرایش شد",
            },
          },
        ]}
      />
    </PageBuilderComp>
  );
};
export default EditCustomer;
