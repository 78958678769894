import { useEffect, useState } from "react";
import { __DayType, __MonthType, __WeekDayType } from "./baseCalendar.type";

const weekDays = 7;
const yearMonths = 12;

export const __useDateUtils = (
  ddd: string | Date,
  locale?: string,
  calendar?: string,
  weekStartDay = 1
) => {
  const [base, setBase] = useState<Date>(new Date(ddd));
  const [days, setDays] = useState<__DayType[][]>(generateDays());
  const [week, setWeek] = useState<__WeekDayType[]>(generateWeeks());
  const [months, setMonths] = useState<__MonthType[][]>(generateMonths());
  const baseYear = {
    number: Number(
      new Intl.DateTimeFormat("en", { calendar, year: "numeric" })
        .format(base)
        .replace(/\D/g, "")
    ),
    title: new Intl.DateTimeFormat(locale, {
      calendar,
      year: "numeric",
    }).format(base),
  };
  const baseMonth = {
    number: Number(
      new Intl.DateTimeFormat("en", { calendar, month: "numeric" })
        .format(base)
        .replace(/\D/g, "")
    ),
    title: new Intl.DateTimeFormat(locale, { calendar, month: "long" }).format(
      base
    ),
  };

  useEffect(() => {
    setDays(generateDays());
    setWeek(generateWeeks());
    setMonths(generateMonths());
    console.log("----------", locale, weekStartDay, calendar);
  }, [base, locale, weekStartDay, calendar]);

  function generateDays() {
    const baseDay = Number(
      new Intl.DateTimeFormat("en", { calendar, day: "numeric" })
        .format(base)
        .replace(/\D/g, "")
    );
    const nexMonth = new Date(base);
    nexMonth.setMonth(nexMonth.getMonth() + 1);
    const day = new Date(base);
    day.setDate(day.getDate() - baseDay + 1);
    if (calcWeekDay(day.getDay()) > 0) {
      day.setDate(day.getDate() - calcWeekDay(day.getDay()));
    }
    const monthView: __DayType[][] = [];
    while (
      Number(
        new Intl.DateTimeFormat("en", { calendar, month: "numeric" })
          .format(day)
          .replace(/\D/g, "")
      ) !==
        Number(
          new Intl.DateTimeFormat("en", { calendar, month: "numeric" })
            .format(nexMonth)
            .replace(/\D/g, "")
        ) ||
      calcWeekDay(day.getDay()) !== 0
    ) {
      const data = {
        day: Number(
          new Intl.DateTimeFormat("en", { calendar, day: "numeric" })
            .format(day)
            .replace(/\D/g, "")
        ),
        dayTitle: new Intl.DateTimeFormat(locale, {
          calendar,
          day: "numeric",
        }).format(day),
        month: Number(
          new Intl.DateTimeFormat("en", { calendar, month: "numeric" })
            .format(day)
            .replace(/\D/g, "")
        ),
        year: Number(
          new Intl.DateTimeFormat("en", { calendar, year: "numeric" })
            .format(day)
            .replace(/\D/g, "")
        ),
        date: new Date(day.getTime()),
        weekDay: calcWeekDay(day.getDay()).toString(),
      };
      if (
        !monthView.length ||
        monthView[monthView.length - 1].length === weekDays
      ) {
        monthView.push([data]);
      } else monthView[monthView.length - 1].push(data);
      day.setDate(day.getDate() + 1);
    }
    return monthView;
  }

  function generateWeeks() {
    const clone = new Date(base);
    const weeks: __WeekDayType[] = [];
    if (calcWeekDay(clone.getDay()) > 0) {
      clone.setDate(clone.getDate() - calcWeekDay(clone.getDay()));
    }
    for (let i = 0; i < weekDays; i++) {
      weeks.push({
        short: new Intl.DateTimeFormat(locale, {
          calendar,
          weekday: "narrow",
        }).format(clone),
        long: new Intl.DateTimeFormat(locale, {
          calendar,
          weekday: "narrow",
        }).format(clone),
      });
      clone.setDate(clone.getDate() + 1);
    }
    return weeks;
  }

  function generateMonths() {
    const clone = new Date(base);
    const result: __MonthType[] = [];
    for (let i = 0; i < yearMonths; i++) {
      const index = Number(
        new Intl.DateTimeFormat("en", { calendar, month: "numeric" })
          .format(clone)
          .replace(/\D/g, "")
      );
      const day = Number(
        new Intl.DateTimeFormat("en", { calendar, day: "numeric" })
          .format(clone)
          .replace(/\D/g, "")
      );
      const title = new Intl.DateTimeFormat(locale, {
        calendar,
        month: "long",
      }).format(clone);
      result.push({
        number: index,
        title,
      });
      clone.setDate(clone.getDate() - day);
    }
    const final: __MonthType[][] = [];
    result
      .sort((a, b) => a.number - b.number)
      .map((month, index) => {
        if (final.length === 0 || final[final.length - 1].length === 3) {
          final.push([month]);
        } else final[final.length - 1].push(month);
      });
    return final;
  }

  function calcWeekDay(val: number) {
    const newVal = val - weekStartDay;
    if (newVal < 0) return newVal + weekDays;
    else return newVal;
  }

  function setYearMonth(year: number, month: number) {
    const temp = new Date(base);
    let tempYear = Number(
      new Intl.DateTimeFormat("en", { calendar, year: "numeric" })
        .format(temp)
        .replace(/\D/g, "")
    );
    let tempMonth = Number(
      new Intl.DateTimeFormat("en", { calendar, month: "numeric" })
        .format(temp)
        .replace(/\D/g, "")
    );
    while (tempYear !== year) {
      const dayDiff = 366 / 2;
      if (tempYear > year) temp.setDate(temp.getDate() - dayDiff);
      else temp.setDate(temp.getDate() + dayDiff);
      tempYear = Number(
        new Intl.DateTimeFormat("en", { calendar, year: "numeric" })
          .format(temp)
          .replace(/\D/g, "")
      );
    }
    while (tempMonth !== month) {
      const dayDiff = 30 / 2;
      if (tempMonth > month) temp.setDate(temp.getDate() - dayDiff);
      else temp.setDate(temp.getDate() + dayDiff);
      tempMonth = Number(
        new Intl.DateTimeFormat("en", { calendar, month: "numeric" })
          .format(temp)
          .replace(/\D/g, "")
      );
    }
    setBase(temp);
  }

  return {
    days,
    week,
    months,
    base: { year: baseYear, month: baseMonth },
    set: setYearMonth,
  };
};
