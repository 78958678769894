import React from "react";
import { APIPath, FormsConfig, RoutePath } from "data";
import { FormBuilderComp, PageBuilderComp } from "components";
import { useHistory } from "react-router-dom";

const CreateImage: React.FC = function () {
  const history = useHistory();
  return (
    <PageBuilderComp
      title="ایجاد تصویر جدید"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "گالری", path: RoutePath.gallery.index },
        { title: "ایجاد تصویر جدید" },
      ]}
    >
      <FormBuilderComp
        groups={FormsConfig.gallery.groups}
        submitParser={FormsConfig.gallery.submitParser}
        api={APIPath.gallery()}
        actions={[
          { title: "بازگشت", onClick: "back" },
          {
            title: "ساخت",
            onClick: () => history.goBack(),
            submit: {
              enable: true,
              success: "تصویر با موفقیت ایجاد شد",
            },
          },
        ]}
      />
    </PageBuilderComp>
  );
};
export default CreateImage;
