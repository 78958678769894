import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useImperativeHandle, useRef } from "react";
import { Controller } from "react-hook-form";
import {
  __EditorInitType,
  __EditorKeyEnum,
  __EditorLoadType,
  __EditorSubmitType,
  __InputProps,
  __LocalCompProp,
  __LocalCompRefType,
} from "./pageInput.type";
import "./pageInput.style.scss";
import { RoutePath } from "data";
import { TbX } from "react-icons/tb";
import { IconButtonComp } from "utilities";
import { useEventListener } from "scripts";

const __PageInputComp: React.FC<__InputProps> = (props: __InputProps) => {
  const compRef = useRef<__LocalCompRefType>(null);
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => (
        <>
          <Comp
            ref={compRef}
            onChange={(serialize, html, maxWidth, backgroundColor) =>
              field.onChange({ html, serialize, maxWidth, backgroundColor })
            }
            html={field.value?.html}
            serialize={field.value?.serialize}
            maxWidth={field.value?.maxWidth}
            backgroundColor={field.value?.backgroundColor}
          />
          <TextField
            fullWidth={true}
            className={`app-page-utility ${
              props.className ? props.className : ""
            }`}
            label={props.label}
            placeholder={props.placeholder}
            variant={props.variant ? props.variant : "outlined"}
            disabled={Boolean(props.disabled)}
            error={Boolean(fieldState.error)}
            helperText={
              fieldState.error && fieldState.error.message
                ? fieldState.error.message
                : props.hint
            }
            required={props.required}
            InputProps={{
              onBlur: field.onBlur,
              ref: field.ref,
              onClick: () => {
                compRef.current?.open();
              },
              autoComplete: "new-password",
              endAdornment: field.value ? (
                <InputAdornment position="end">
                  <IconButtonComp
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      field.onChange(null);
                    }}
                  >
                    <TbX />
                  </IconButtonComp>
                </InputAdornment>
              ) : undefined,
            }}
            value={field.value ? "طراحی شده" : ""}
          />
        </>
      )}
    />
  );
};

export default __PageInputComp;

const CompFunc: React.ForwardRefRenderFunction<
  __LocalCompRefType,
  __LocalCompProp
> = (props: __LocalCompProp, ref) => {
  const winRef = useRef<Window | null>(null);

  const messageHandler = (e: any) => {
    let payload: __EditorSubmitType | __EditorLoadType | undefined = undefined;
    try {
      payload = JSON.parse(e.data);
    } catch (exeption) {
      console.log("on message parse error", e.data);
    }
    if (payload && payload.key === __EditorKeyEnum.submit) {
      console.log(payload);
      props.onChange(
        payload.serialize,
        payload.html,
        payload.maxWidth ?? 0,
        payload.backgroundColor ?? ""
      );
      winRef.current?.close();
    } else if (payload && payload.key === __EditorKeyEnum.load) {
      sendData();
    }
  };

  useEventListener("message", messageHandler, window);

  const sendData = () => {
    const payload: __EditorInitType = {
      key: __EditorKeyEnum.init,
      serialize: props.serialize,
      maxWidth: props.maxWidth,
      backgroundColor: props.backgroundColor,
    };
    console.log("-----------", props.maxWidth);
    winRef.current?.postMessage(JSON.stringify(payload), {
      targetOrigin: window.location.origin,
    });
  };

  useEffect(sendData, [props.html, props.serialize]);

  useImperativeHandle(ref, () => ({
    open: () => {
      winRef.current = window.open(RoutePath.pageEditor, "_blank");
    },
  }));

  return null;
};

const Comp = React.forwardRef(CompFunc);
