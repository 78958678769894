import React from "react";
import { APIPath, FormsConfig, RoutePath } from "data";
import { useParams } from "react-router-dom";
import { FormBuilderComp, PageBuilderComp } from "components";

const EditPost: React.FC = function () {
  const { id } = useParams<any>();

  return (
    <PageBuilderComp
      title="ویرایش پست"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "پست ها", path: RoutePath.blogPost.index },
        { title: "ویرایش پست" },
      ]}
    >
      <FormBuilderComp
        {...FormsConfig.post}
        api={APIPath.post(id)}
        actions={[
          { title: "بازگشت", onClick: "back" },
          {
            title: "ویرایش",
            submit: {
              enable: true,
              success: "پست با موفقیت ویرایش شد",
            },
          },
        ]}
      />
    </PageBuilderComp>
  );
};
export default EditPost;
