import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { __ModalProps, __ModalUx } from "./modal.type";

const __ModalComp: React.FC<React.PropsWithChildren<__ModalProps>> = (
  props: React.PropsWithChildren<__ModalProps>
) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const temp = isMobile
    ? props.responsiveUX
      ? props.responsiveUX
      : props.ux
    : props.ux
    ? props.ux
    : props.responsiveUX;
  const ux: __ModalUx = temp ? temp : "center";

  const content = (
    <>
      {props.title ? <DialogTitle>{props.title}</DialogTitle> : null}
      <DialogContent
        className={`pt-1-i ${props.className ? props.className : ""}`}
      >
        {props.children}
      </DialogContent>
      {props.actions ? <DialogActions>{props.actions}</DialogActions> : null}
    </>
  );

  if ("center" === ux || "page" === ux) {
    return (
      <Dialog
        maxWidth={props.maxWidth}
        fullScreen={ux === "page"}
        fullWidth
        open={Boolean(props.display)}
        onClose={props.onClose}
      >
        {content}
      </Dialog>
    );
  } else {
    return (
      <Drawer anchor={ux} open={props.display} onClose={props.onClose}>
        {content}
      </Drawer>
    );
  }
};

export default __ModalComp;
