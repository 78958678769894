import {
  AppContextActionKeyEnum,
  AppContextActionType,
  AppContextType,
} from "../types";

// helper function for container.tsx
// check type and set given value to exact field of state in order to given type
export function globalStateSetter(
  e: AppContextActionType[],
  state: AppContextType
): AppContextType {
  const newState: AppContextType = { ...state };
  for (const action of e) {
    switch (action.key) {
      case AppContextActionKeyEnum.profile:
        newState.profile = action.value;
        break;
      case AppContextActionKeyEnum.authStatus:
        newState.authStatus = action.value;
        break;
      case AppContextActionKeyEnum.formDefault:
        newState.formDefaultValues = action.value;
        break;
      case AppContextActionKeyEnum.language:
        newState.language = action.value;
        break;
    }
  }
  return newState;
}
