type routeParam = string | number;

// absolute paths of project pages
export const __PATH = {
  home: "/",
  dashboard: { index: "/dashboard/" },
  profile: "/profile/",
  authenticate: {
    index: "/authenticate",
    signup: "/authenticate/signup",
    login: "/authenticate/login",
  },
  product: {
    index: "/product/",
    create: "/product/new/",
    edit: (id: string = ":id") => `/product/${id}`,
  },
  productCategory: {
    index: "/productCategory/",
    create: "/productCategory/new/",
    edit: (id: string = ":id") => `/productCategory/${id}`,
  },
  blogCategory: {
    index: "/blogCategory/",
    create: "/blogCategory/new",
    edit: (id: string = ":id") => `/blogCategory/${id}`,
  },
  blogPost: {
    index: "/blogPost/",
    create: "/blogPost/new",
    edit: (id: string = ":id") => `/blogPost/${id}`,
  },
  gallery: {
    index: "/galley/",
    create: "/galley/new",
    edit: (id: number | string = ":id") => `/galley/${id}`,
  },
  customer: {
    index: "/customer/",
    create: "/customer/new",
    edit: (id: number | string = ":id") => `/customer/${id}`,
  },
  contact: {
    index: "/contact-forms/",
    detail: (id: number | string = ":id") => `/contact-forms/${id}`,
  },
  work: {
    index: "/work-with-us-forms/",
    detail: (id: number | string = ":id") => `/work-with-us-forms/${id}`,
  },
  attachments: "/attachments/",
  pageEditor: "/page-editor/",
};
