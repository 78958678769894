import { RoutePath } from "data";
import React from "react";
import { Route, Switch } from "react-router-dom";
import DetailForm from "./detail/detail.index";
import ListForm from "./list/list.index";
const ContactComp: React.FC = function () {
  return (
    <Switch>
      <Route path={RoutePath.contact.detail()}>
        <DetailForm />
      </Route>
      <Route path={"*"}>
        <ListForm />
      </Route>
    </Switch>
  );
};
export default ContactComp;
