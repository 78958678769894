import { RoutePath } from "data";
import React from "react";
import { Route, Switch } from "react-router-dom";
import CreatePost from "./create/create.index";
import EditPost from "./edit/edit.index";
import ListPost from "./list/list.index";

const GalleryComp: React.FC = function () {
  return (
    <Switch>
      <Route path={RoutePath.gallery.create}>
        <CreatePost />
      </Route>
      <Route path={RoutePath.gallery.edit()}>
        <EditPost />
      </Route>
      <Route path={"*"}>
        <ListPost />
      </Route>
    </Switch>
  );
};
export default GalleryComp;
