import { RoutePath } from "data";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CreateBlogCategoryComp from "./create/create.index";
import EditBlogCategoryComp from "./edit/edit.index";
import ListBlogCategoryComp from "./list/list.index";

const BlogCategoryComp: React.FC = function () {
  return (
    <Switch>
      <Route path={RoutePath.blogCategory.create}>
        <CreateBlogCategoryComp />
      </Route>
      <Route path={RoutePath.blogCategory.edit()}>
        <EditBlogCategoryComp />
      </Route>
      <Route path={RoutePath.blogCategory.index}>
        <ListBlogCategoryComp />
      </Route>
      <Route path={"*"}>
        <Redirect to={RoutePath.blogCategory.index} />
      </Route>
    </Switch>
  );
};
export default BlogCategoryComp;
