import { Box, Chip, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { __MultiSelectProps } from "./multiSelect.type";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { IconButtonComp } from "utilities";
import { TbX } from "react-icons/tb";
const __MultiSelectComp: React.FC<__MultiSelectProps> = (
  props: __MultiSelectProps
) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => (
        <FormControl
          className={`mb-1 ${props.className ? props.className : ""}`}
          fullWidth
        >
          <InputLabel>{props.label ? props.label : props.name}</InputLabel>
          <Select
            disabled={props.disabled}
            variant={props.variant ? props.variant : "outlined"}
            multiple={true}
            error={Boolean(fieldState.error)}
            input={<OutlinedInput label={props.label ? props.label : ""} />}
            renderValue={() => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {field.value?.map((item: number) => (
                  <Chip
                    onDelete={() =>
                      field.onChange(
                        field.value.filter((i: number) => i !== item)
                      )
                    }
                    deleteIcon={
                      <IconButtonComp
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          field.onChange(
                            field.value
                              ? field.value.filter((i: number) => i !== item)
                              : []
                          );
                        }}
                      >
                        <TbX />
                      </IconButtonComp>
                    }
                    key={item}
                    className="ml-1"
                    label={props.options.find((i) => i.payload === item)?.title}
                  />
                ))}
              </Box>
            )}
            {...field}
            value={field.value?.length ? field.value : []}
          >
            {props.options.map((item) => (
              <MenuItem key={item.payload} value={item.payload}>
                <Checkbox
                  checked={
                    field.value && field.value.length !== 0
                      ? field.value.indexOf(item.payload) > -1
                      : false
                  }
                />
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default __MultiSelectComp;
