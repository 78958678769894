import React, { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  __FormActionType,
  __FormFieldsType,
  __FormGroupType,
  __FormBuilderProps,
} from "./formBuilder.type";
import * as yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import {
  ButtonComp,
  CheckboxComp,
  BaseCalendarComp,
  InputComp,
  MultiAutoCompleteComp,
  MultiFileAttachComp,
  MultiSelectComp,
  PageInputComp,
  PriceComp,
  SelectComp,
  SingleAutoCompleteComp,
  SingleFileAttachComp,
  TextEditor4Comp,
  InputSlugComp,
  TableInputComp,
} from "utilities";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { RestAPI } from "scripts";
import "./formBuilder.style.scss";
import { CircularProgress } from "@mui/material";
import { GlobalContext } from "context";
import { AppContextActionKeyEnum } from "types";

const __FromBuilderComp: React.FC<__FormBuilderProps> = function (
  props: __FormBuilderProps
) {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(
    props.initParser ? true : false
  );
  const { formDefaultValues, update } = useContext(GlobalContext);
  let fields: __FormFieldsType[] = [];
  const validationData: any = {};
  const validationValues: any = {};
  props.groups?.map((group) => {
    fields = [...fields, ...group.fields];
  });
  fields.map((item) => {
    validationData[item.key] = item.validation;
    if (item.type === "slug")
      validationData[item.key] = item.validation.matches(
        /^[^\\/;?@&=+,$#"<>^]*$/,
        "آدرس نباید شامل حروف خاص باشد."
      );
    if (item.default) validationValues[item.key] = item.default;
  });
  const validationSchema = yup.object().shape(validationData);

  const { handleSubmit, control, watch, setValue, getValues, formState } =
    useForm<FieldValues>({
      resolver: yupResolver(validationSchema),
      reValidateMode: "onChange",
      mode: "onSubmit",
    });

  useEffect(() => {
    fields.map((item) => {
      if (item.default) setValue(item.key, item.default);
    });
  }, [props.groups]);

  useEffect(() => {
    console.log(formDefaultValues);
    formDefaultValues.map((item) => {
      setValue(item.key, item.value);
    });
    if (formDefaultValues.length)
      update({ key: AppContextActionKeyEnum.formDefault, value: [] });
  }, [formDefaultValues]);

  const values = watch(fields.map((i) => i.key));

  useEffect(() => {
    if (props.api && (props.initParser || props.onLoad)) {
      setLoading(true);
      RestAPI.get<any>(props.api)
        .then((res) => {
          if (props.onLoad) props.onLoad(res.data);
          const data = {
            ...res.data,
            ...(props.initParser ? props.initParser(res.data) : {}),
          };
          for (const key of Object.keys(data)) setValue(key, data[key]);
        })
        .catch(() => {
          toast.error("خطایی به وجود آمده است. لطفا محددا تلاش نمایید.");
          // history.goBack();
        })
        .finally(() => setLoading(false));
    }
  }, [props.initParser, props.api]);

  useEffect(() => {
    if (props.onPayloadChange) props.onPayloadChange(getValues(), formState);
  }, [values, props.onPayloadChange]);

  function onClickHandler(action: __FormActionType, response?: any) {
    if (action.onClick === "back") history.goBack();
    else if (action.onClick) action.onClick(formState, response);
  }
  function onSubmitHandler(action: __FormActionType) {
    return new Promise((resolve) => {
      if (props.api) {
        const promise = props.initParser ? RestAPI.patch : RestAPI.post;
        promise(props.api, {
          ...getValues(),
          ...(props.submitParser
            ? props.submitParser(getValues(), formState)
            : {}),
        })
          .then((res) => {
            if (action.submit?.enable)
              toast.success(
                action.submit.success
                  ? action.submit.success
                  : "عملیات با موفقیت انجام شد"
              );
            onClickHandler(action, res.data);
          })
          .catch(() => {
            toast.error(
              action.submit?.error
                ? action.submit.error
                : "خطایی به وجود آمده است. لطفا محددا تلاش نمایید."
            );
          })
          .finally(() => {
            resolve(true);
          });
      } else {
        if (action.submit)
          toast.success(
            action.submit.error
              ? action.submit.error
              : "خطایی پیش آمده است. لطفا مجددا تلاش نمایید"
          );
        onClickHandler(action);
      }
    });
  }
  if (loading) {
    return (
      <div className="ens-base-form-comp-loading-state">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="ens-base-form-comp">
      {props.groups?.map((group, index) => (
        <div key={index}>
          <h3>{group.title}</h3>
          <p>{group.description}</p>
          {group.fields.map((field, index) => (
            <React.Fragment key={index}>
              {field.type === "text" ? (
                <InputComp
                  control={control}
                  name={field.key}
                  label={field.title}
                  maxLength={field.maxLength}
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "slug" ? (
                <InputSlugComp
                  control={control}
                  name={field.key}
                  label={field.title}
                  maxLength={field.maxLength}
                  disabled={field.disabled}
                  prefix={field.prefix}
                />
              ) : null}
              {field.type === "number" ? (
                <InputComp
                  control={control}
                  name={field.key}
                  label={field.title}
                  type="number"
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "textarea" ? (
                <InputComp
                  control={control}
                  name={field.key}
                  label={field.title}
                  multiline
                  maxLength={field.maxLength}
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "tableInput" ? (
                <TableInputComp
                  control={control}
                  name={field.key}
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "multiAutoComplete" ? (
                <MultiAutoCompleteComp
                  control={control}
                  name={field.key}
                  label={field.title}
                  api={field.api}
                  listProps={{
                    fields: field.listProps.fields,
                    defaultFilters: field.listProps.defaultFilters,
                    order: field.listProps.order,
                    searchKey: field.listProps.searchKey,
                    convertToSelectOption:
                      field.listProps.convertToSelectOption,
                  }}
                  formProps={
                    field.formProps
                      ? {
                          groups: field.formProps?.groups,
                          initParser: field.formProps?.initParser,
                          submitParser: field.formProps?.submitParser,
                        }
                      : undefined
                  }
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "singleAutoComplete" ? (
                <SingleAutoCompleteComp
                  control={control}
                  name={field.key}
                  label={field.title}
                  api={field.api}
                  listProps={{
                    fields: field.listProps.fields,
                    defaultFilters: field.listProps.defaultFilters,
                    order: field.listProps.order,
                    searchKey: field.listProps.searchKey,
                    convertToSelectOption:
                      field.listProps.convertToSelectOption,
                  }}
                  formProps={
                    field.formProps
                      ? {
                          disableCreate: field.formProps.disableCreate,
                          groups: field.formProps?.groups,
                          initParser: field.formProps?.initParser,
                          submitParser: field.formProps?.submitParser,
                        }
                      : undefined
                  }
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "singleFileAttachment" ? (
                <SingleFileAttachComp
                  accept={field.accept}
                  control={control}
                  name={field.key}
                  label={field.title}
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "multiFileAttachment" ? (
                <MultiFileAttachComp
                  accept={field.accept}
                  control={control}
                  name={field.key}
                  label={field.title}
                  limit={field.limit}
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "price" ? (
                <PriceComp
                  control={control}
                  name={field.key}
                  label={field.title}
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "boolean" ? (
                <CheckboxComp
                  control={control}
                  name={field.key}
                  label={field.title}
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "date" ? (
                <BaseCalendarComp
                  control={control}
                  name={field.key}
                  label={field.title}
                  min={field.minDate}
                  max={field.maxDate}
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "textEditor" ? (
                <TextEditor4Comp
                  control={control}
                  name={field.key}
                  label={field.title}
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "select" ? (
                <SelectComp
                  control={control}
                  name={field.key}
                  label={field.title}
                  options={field.options}
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "multiSelect" ? (
                <MultiSelectComp
                  control={control}
                  name={field.key}
                  label={field.title}
                  options={field.options}
                  disabled={field.disabled}
                />
              ) : null}
              {field.type === "page" ? (
                <PageInputComp
                  control={control}
                  name={field.key}
                  label={field.title}
                  disabled={field.disabled}
                />
              ) : null}
            </React.Fragment>
          ))}
        </div>
      ))}
      <div className="actions">
        {props.actions?.map((action, index) => {
          return (
            <ButtonComp
              key={index}
              onClick={
                action.submit
                  ? handleSubmit(() => onSubmitHandler(action))
                  : () => onClickHandler(action)
              }
              variant={
                action.onClick === "back" && !action.variant
                  ? "text"
                  : action.variant
              }
            >
              {action.title}
            </ButtonComp>
          );
        })}
      </div>
    </div>
  );
};
export default __FromBuilderComp;
