import { AuthTokenEnum, AuthTokenType, LanguageEnum } from "types";

// local storage interface
const tokenStatus = {
  key: "PTK_ADMIN_TOKEN_STATUS",
  get: function (): AuthTokenEnum {
    const status: string | null | undefined = localStorage.getItem(
      tokenStatus.key
    );
    if (status === AuthTokenEnum.refreshing) return AuthTokenEnum.refreshing;
    else if (status === AuthTokenEnum.valid) return AuthTokenEnum.valid;
    else return AuthTokenEnum.invalid;
  },
  set: (value: AuthTokenEnum) => {
    localStorage.setItem(tokenStatus.key, value);
  },
  remove: () => {
    localStorage.removeItem(tokenStatus.key);
  },
};

const authToken = {
  key: "PTK_ADMIN_AUTH_TOKEN",
  get: function (): AuthTokenType | null {
    const token: string | null | undefined = localStorage.getItem(
      authToken.key
    );
    if (!token) return null;
    try {
      return JSON.parse(token);
    } catch {
      return null;
    }
  },
  set: (value: AuthTokenType) => {
    localStorage.setItem(authToken.key, JSON.stringify(value));
  },
  remove: () => {
    localStorage.removeItem(authToken.key);
  },
};

export const defaultLang = {
  key: "PTK_ADMIN_LANG",
  get: function (): LanguageEnum | null {
    const view = localStorage.getItem(defaultLang.key);
    if (view === undefined || view === null) return null;
    else {
      if (view === LanguageEnum.en.toString()) return LanguageEnum.en;
      else return LanguageEnum.fa;
    }
  },
  set: (value: LanguageEnum) => {
    localStorage.setItem(defaultLang.key, value.toString());
  },
  remove: () => {
    localStorage.removeItem(defaultLang.key);
  },
};

export const __AppStorage = {
  authToken,
  tokenStatus,
  defaultLang,
};
