import React, { useContext, useRef } from "react";
import { APIPath, FormsConfig, RoutePath } from "data";
import { FormBuilderComp, PageBuilderComp } from "components";
import { useHistory } from "react-router-dom";
import { AiOutlineImport } from "react-icons/ai";
import { AppContextActionKeyEnum, TechnicalSpecificationType } from "types";
import { GlobalContext } from "context";
import { toast } from "react-toastify";

const CreateProduct: React.FC = function () {
  const history = useHistory();
  const ref = useRef<HTMLInputElement>(null);
  const { update } = useContext(GlobalContext);

  function loadData(fileList: FileList | null) {
    if (!fileList?.length) return;
    const file = fileList[0];
    file.text().then((res) => {
      const result: TechnicalSpecificationType[] = [];
      res
        .split("\n")
        .filter((i) => i.length > 0)
        .map((i, index) => {
          if (index > 0) {
            const row = i.split(",");
            console.log(row);
            result.push({
              serial_number: row[0],
              parameters: row[1],
              unit: row[2],
              standard: row[3],
            });
          }
        });
      if (result.length)
        update({
          key: AppContextActionKeyEnum.formDefault,
          value: [
            {
              key: "technical_specification",
              value: result,
            },
          ],
        });
      else toast.error("فایل اتخابی فاقد محتواست");
    });
  }

  return (
    <PageBuilderComp
      title="ایجاد محصول جدید"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "محصولات", path: RoutePath.product.index },
        { title: "ایجاد محصول جدید" },
      ]}
      actions={[
        {
          title: "وارد کردن مشخصه های فنی از طریق اکسل",
          icon: () => <AiOutlineImport />,
          onClick: () => ref.current?.click(),
        },
      ]}
    >
      <input
        ref={ref}
        onChange={(e) => loadData(e.target.files)}
        type="file"
        accept="text/csv"
        className="d-none"
      />
      <FormBuilderComp
        groups={FormsConfig.product.groups}
        submitParser={FormsConfig.product.submitParser}
        api={APIPath.product()}
        actions={[
          { title: "بازگشت", onClick: "back" },
          {
            title: "ساخت",
            onClick: () => history.goBack(),
            submit: {
              enable: true,
              success: "محصول با موفقیت ایجاد شد",
            },
          },
        ]}
      />
    </PageBuilderComp>
  );
};
export default CreateProduct;
