import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import "./checkbox.style.scss";
import { __InputProps } from "./checkbox.type";

const __CheckboxComp: React.FC<__InputProps> = (props: __InputProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <FormControlLabel
          className={`app-checkbox-utility typo-gray-2 ${
            props.className ? props.className : ""
          }`}
          disabled={props.disabled}
          control={
            <Checkbox
              checked={Boolean(field.value)}
              size={props.size}
              color={props.color}
              icon={props.icon}
              checkedIcon={props.checkedIcon}
            />
          }
          {...field}
          label={props.label}
        />
      )}
    />
  );
};

export default __CheckboxComp;
