import { LanguageEnum } from "types";

export interface __ProductType {
  created_at: string;
  id: number;
  lang: LanguageEnum;
  title: string;
  slug: string;
  description?: string;
  content?: string;
  main_image?: __FileAttachmentType;
  category?: __ProductCategoryType;
  images?: __FileAttachmentType[];
  features: string[];
  applications: string[];
  benefits: string[];
  technical_specification: __TechnicalSpecificationType[];
}
export interface __ProductAPIType {
  created_at: string;
  id: number;
  lang: LanguageEnum;
  title: string;
  slug: string;
  description?: string;
  content?: string;
  main_image?: __FileAttachmentType;
  category?: __ProductCategoryType;
  images?: __FileAttachmentType[];
  features: string[];
  applications: string[];
  benefits: string[];
  technical_specification: { list: __TechnicalSpecificationType[] };
}
export interface __TechnicalSpecificationType {
  serial_number: string;
  parameters: string;
  unit: string;
  standard: string;
}

export enum __FileTypeAttachmentEnum {
  image = 1,
  document,
}
export interface __FileAttachmentType {
  alt: string;
  created_at: string;
  type: __FileTypeAttachmentEnum;
  file: string;
  id: number;
}

export interface __ProductCategoryType {
  created_at: string;
  description: string;
  id: number;
  parent?: __ProductCategoryType;
  lang: LanguageEnum;
  title: string;
  slug: string;
  image: __FileAttachmentType;
}

export interface __BlogCategoryType {
  created_at: string;
  description: string;
  id: number;
  lang: LanguageEnum;
  parent?: __BlogCategoryType;
  title: string;
  slug: string;
}

export interface __BlogPostType {
  id: number;
  title: string;
  lang: LanguageEnum;
  description: string;
  content: string;
  slug: string;
  image: __FileAttachmentType;
  category: __BlogCategoryType;
  created_at: string;
}

export interface __ContactFormType {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  description: string;
  subject: string;
}

export interface __WorkWithFormType {
  id: number;
  fullname: string;
  company_name: string;
  email: string;
  subject: string;
  description: string;
}
export interface __CustomerType {
  id: number;
  title: string;
  image: __FileAttachmentType;
  lang: LanguageEnum;
}

export type __GalleryType = {
  id: number;
  order: number;
  file: __FileAttachmentType;
};
