import { APIPath } from "data";
import { AppStorage, RestAPI } from "scripts";
import {
  AppContextActionKeyEnum,
  AppContextActionType,
  AuthStatusEnum,
  AuthTokenEnum,
  ProfileType,
} from "types";

export const __registerCheck = (
  update: (...e: AppContextActionType[]) => void
) => {
  return new Promise((resolve) => {
    RestAPI.get<ProfileType>(APIPath.auth.me, { lang: null })
      .then((profile) => {
        AppStorage.tokenStatus.set(AuthTokenEnum.valid);
        update(
          {
            key: AppContextActionKeyEnum.authStatus,
            value: AuthStatusEnum.valid,
          },
          { key: AppContextActionKeyEnum.profile, value: profile.data }
        );
      })
      .catch(() => __logout(update))
      .then(() => resolve(true));
  });
};

export const __logout = (update: (...e: AppContextActionType[]) => void) => {
  AppStorage.tokenStatus.set(AuthTokenEnum.invalid);
  AppStorage.authToken.remove();
  update({
    key: AppContextActionKeyEnum.authStatus,
    value: AuthStatusEnum.invalid,
  });
};
