import React from "react";
import { APIPath, FormsConfig, RoutePath } from "data";
import { FormBuilderComp, PageBuilderComp } from "components";
import { useHistory } from "react-router-dom";

const CreatePost: React.FC = function () {
  const history = useHistory();
  return (
    <PageBuilderComp
      title="ایجاد پست جدید"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "پست ها", path: RoutePath.blogPost.index },
        { title: "ایجاد پست جدید" },
      ]}
    >
      <FormBuilderComp
        groups={FormsConfig.post.groups}
        submitParser={FormsConfig.post.submitParser}
        api={APIPath.post()}
        actions={[
          { title: "بازگشت", onClick: "back" },
          {
            title: "ساخت",
            onClick: () => history.goBack(),
            submit: {
              enable: true,
              success: "پست با موفقیت ایجاد شد",
            },
          },
        ]}
      />
    </PageBuilderComp>
  );
};
export default CreatePost;
