import React, { useEffect, useRef, useState } from "react";
import ModalComp from "../modal/modal.index";
import { RestAPI } from "scripts";
import { ButtonComp } from "utilities";
import { CircularProgress, Pagination } from "@mui/material";
import { __URLToolbarProps } from "./gallery.type";
import { APIPath } from "data";
import {
  FileAttachmentType,
  FileTypeAttachmentEnum,
  PaginationType,
} from "types";
import "./gallery.style.scss";
import { toast } from "react-toastify";
const pageSize = 24;
const imgFormats = ["png", "jpg", "jpeg", "gif", "svg", "webp"];
const videoFormat = ["mp4", "avi", "mov"];
const __GalleryToolbarOptionComp: React.FC<__URLToolbarProps> = (
  props: __URLToolbarProps
) => {
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<PaginationType<FileAttachmentType> | null>();
  const [selected, setSelected] = useState<FileAttachmentType>();
  const imageRef = useRef<HTMLInputElement>(null);

  useEffect(getData, [page, props.display]);

  function getData() {
    setData(undefined);
    if (props.display) {
      let ext;
      if (props.display === "image")
        ext = [...imgFormats, imgFormats.map((i) => i.toUpperCase())];
      else if (props.display === "video")
        ext = [...videoFormat, videoFormat.map((i) => i.toUpperCase())];
      RestAPI.get<PaginationType<FileAttachmentType>>(APIPath.attachment(), {
        page,
        page_size: pageSize,
        type: FileTypeAttachmentEnum.image,
        ordering: "-id",
      })
        .then((res) => setData(res.data))
        .catch(() => setData(null));
    }
  }

  const submitHandler = () => {
    if (selected) props.onSubmit(selected);
  };

  function onChangeHandler(e: FileList | null) {
    if (!e) return;
    setData(undefined);
    const FILE = e[0];
    const form = new FormData();
    const title = FILE.name.toLocaleLowerCase().split(".");
    form.append("file", FILE);
    form.append("type", FileTypeAttachmentEnum.image.toString());
    form.append("alt", FILE.name);
    RestAPI.form<FileAttachmentType>(APIPath.attachment(), form)
      .then((res) => {
        if (page === 1) getData();
        else setPage(1);
      })
      .catch(() => {
        toast.error("خطایی به وجود آمده است لطفا مجددا تلاش نمایید.");
      });
  }

  let accept = "*";
  if (props.display === "image") accept = "image/*";
  else if (props.display === "video") accept = "video/*";

  return (
    <>
      <ModalComp
        onClose={() => props.onClose()}
        title="گالری"
        display={props.display !== undefined}
        ux="center"
        maxWidth="md"
        responsiveUX="bottom"
        actions={
          <>
            <ButtonComp
              onClick={() => imageRef.current?.click()}
              variant="text"
            >
              افزودن فایل جدید
            </ButtonComp>
            <ButtonComp onClick={() => props.onClose()} variant="text">
              انصراف
            </ButtonComp>
            <ButtonComp
              disabled={!selected}
              variant="text"
              onClick={submitHandler}
            >
              تایید
            </ButtonComp>
          </>
        }
      >
        <input
          onChange={(e) => onChangeHandler(e.target.files)}
          ref={imageRef}
          accept={accept}
          type="file"
          className="d-none"
        />
        <div className="text-editor-gallery-container">
          {data === null ? (
            <div className="no-data-box">
              <label>خطایی به وجود آمده است. مجددا تلاش نمایید</label>
            </div>
          ) : null}
          {data?.count === 0 ? (
            <div className="no-data-box">
              <label>هیچ موردی موجود نیست</label>
            </div>
          ) : null}
          {data && data.count > 0 ? (
            <div className="grid-list">
              {data.data.map((item, index) => (
                <div
                  onClick={(e) => setSelected(item)}
                  className={`box-frame ${
                    item.id === selected?.id ? "active" : ""
                  }`}
                  key={index}
                >
                  <div>
                    {imgFormats.includes(
                      item.file.split(".").pop()?.toLowerCase() ?? ""
                    ) ? (
                      <img src={item.file} alt={item.alt} />
                    ) : null}
                    {videoFormat.includes(
                      item.file.split(".").pop()?.toLowerCase() ?? ""
                    ) ? (
                      <video controls preload="none" src={item.file} />
                    ) : null}
                  </div>
                </div>
              ))}
              <div></div>
              <div></div>
            </div>
          ) : null}
          {data === undefined ? (
            <div className="loading-state">
              <CircularProgress />
            </div>
          ) : null}
          {data && data.num_of_pages > 1 ? (
            <div className="pagination-footer">
              <Pagination
                shape="rounded"
                count={data.num_of_pages}
                page={page}
                onChange={(e, newPage: number) => setPage(newPage)}
              />
            </div>
          ) : null}
        </div>
      </ModalComp>
    </>
  );
};

export default __GalleryToolbarOptionComp;
