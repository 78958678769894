import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
} from "react-hook-form";
import "./tableInput.style.scss";
import {
  TextField,
  Paper,
  FormHelperText,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import { __TableProps } from "./tableInput.type";
import { TechnicalSpecificationType } from "types";
import {
  BsFillPlusCircleFill,
  BsThreeDotsVertical,
  BsXLg,
} from "react-icons/bs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const __TableInputComp: React.FC<__TableProps> = (props: __TableProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<null | {
    index: number;
    el: HTMLElement;
  }>(null);

  const onTableRowChange = (
    field: ControllerRenderProps<FieldValues, string>,
    item: "serial_number" | "parameters" | "unit" | "standard",
    value: string,
    index: number
  ) => {
    const data: TechnicalSpecificationType[] = field.value;
    data[index][item] = value;
    field.onChange([...data]);
  };

  const onAddRow = (field: ControllerRenderProps<FieldValues, string>) => {
    const data: TechnicalSpecificationType[] = field.value;
    const newItem: TechnicalSpecificationType = {
      serial_number: "",
      parameters: "",
      unit: "",
      standard: "",
    };
    data.push(newItem);
    field.onChange([...data]);
  };

  const onRemoveRow = (
    field: ControllerRenderProps<FieldValues, string>,
    index: number
  ) => {
    setIsMenuOpen(null);
    const data: TechnicalSpecificationType[] = field.value;
    data.splice(index, 1);
    field.onChange([...data]);
  };

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => {
        const values: TechnicalSpecificationType[] =
          field.value && field.value.length ? field.value : [];
        console.log("ssssssss", values);
        return (
          <TableContainer
            component={Paper}
            className={`app-table-utility ${
              Boolean(fieldState.error?.message?.length) ? "error" : ""
            }`}
          >
            <p className="mb-2">مشخصه های فنی محصول</p>
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>ردیف</StyledTableCell>
                  <StyledTableCell>شماره سریال</StyledTableCell>
                  <StyledTableCell>پارامترها</StyledTableCell>
                  <StyledTableCell>واحد</StyledTableCell>
                  <StyledTableCell>استاندارد</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        value={row.serial_number ? row.serial_number : ""}
                        onChange={(e) =>
                          onTableRowChange(
                            field,
                            "serial_number",
                            e.target.value,
                            index
                          )
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <TextField
                        value={row.parameters ? row.parameters : ""}
                        onChange={(e) =>
                          onTableRowChange(
                            field,
                            "parameters",
                            e.target.value,
                            index
                          )
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <TextField
                        value={row.unit ? row.unit : ""}
                        onChange={(e) =>
                          onTableRowChange(field, "unit", e.target.value, index)
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <TextField
                        value={row.standard ? row.standard : ""}
                        onChange={(e) =>
                          onTableRowChange(
                            field,
                            "standard",
                            e.target.value,
                            index
                          )
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      {field.value && field.value.length > 1 ? (
                        <Tooltip title="عملیات">
                          <IconButton
                            onClick={(event) =>
                              setIsMenuOpen({
                                el: event.currentTarget,
                                index: index,
                              })
                            }
                          >
                            <BsThreeDotsVertical />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                      <Menu
                        anchorEl={isMenuOpen?.el}
                        open={index === isMenuOpen?.index}
                        onClose={() => setIsMenuOpen(null)}
                      >
                        <MenuItem onClick={() => onRemoveRow(field, index)}>
                          <ListItemIcon>
                            <BsXLg />
                          </ListItemIcon>
                          <ListItemText>حذف</ListItemText>
                        </MenuItem>
                      </Menu>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    <BsFillPlusCircleFill
                      className="pointer add-icon"
                      onClick={() => onAddRow(field)}
                    />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" />
                  <StyledTableCell component="th" scope="row" />
                  <StyledTableCell component="th" scope="row" />
                  <StyledTableCell component="th" scope="row" />
                  <StyledTableCell component="th" scope="row" />
                </StyledTableRow>
              </TableBody>
            </Table>
            {Boolean(fieldState.error?.message?.length) || props.hint ? (
              <FormHelperText
                error={Boolean(fieldState.error?.message?.length)}
              >
                {fieldState.error?.message?.length
                  ? fieldState.error.message
                  : props.hint}
              </FormHelperText>
            ) : null}
          </TableContainer>
        );
      }}
    />
  );
};

export default __TableInputComp;
