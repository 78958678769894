import { useEffect, useRef } from "react";

export function __useEventListener(
  eventName: string,
  handler: (e: any) => void,
  source: Document | Window = document
) {
  // Create a ref that stores handler
  const savedHandler = useRef<(e: any) => void>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = source.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: any) => {
        if (savedHandler.current) {
          savedHandler.current(event);
        }
      };

      // Add event listener
      source.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        source.removeEventListener(eventName, eventListener);
      };
    },
    [eventName] // Re-run if eventName or element changes
  );
}
