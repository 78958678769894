import { RoutePath } from "data";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CreateProductCategoryComp from "./create/create.index";
import EditProductCategoryComp from "./edit/edit.index";
import ListProductCategoryComp from "./list/list.index";

const ProductCategoryComp: React.FC = function () {
  return (
    <Switch>
      <Route path={RoutePath.productCategory.create}>
        <CreateProductCategoryComp />
      </Route>
      <Route path={RoutePath.productCategory.edit()}>
        <EditProductCategoryComp />
      </Route>
      <Route path={RoutePath.productCategory.index}>
        <ListProductCategoryComp />
      </Route>
      <Route path={"*"}>
        <Redirect to={RoutePath.productCategory.index} />
      </Route>
    </Switch>
  );
};
export default ProductCategoryComp;
