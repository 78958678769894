import React, { useContext, useState } from "react";
import "./signIn.style.scss";
import banner from "assets/images/signIn.webp";
import { ButtonComp, InputComp } from "utilities";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { TbUserCheck } from "react-icons/tb";
import { APIPath } from "data";
import { AppStorage, registerCheck, RestAPI } from "scripts";
import { AuthTokenEnum, AuthTokenType } from "types";
import { GlobalContext } from "context";
import { toast } from "react-toastify";

const SignInComp: React.FC = () => {
  const { update } = useContext(GlobalContext);
  const validationSchema = yup.object().shape({
    username: yup.string().required(""),
    password: yup.string().required(""),
  });
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);

  const { handleSubmit, control, getValues } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: { username: "", password: "" },
    reValidateMode: "onChange",
    mode: "onSubmit",
  });

  function onClickHandler() {
    AppStorage.tokenStatus.set(AuthTokenEnum.valid);
    const data = getValues();
    return new Promise((resolve) => {
      RestAPI.post<AuthTokenType>(APIPath.auth.signIn, {
        username: data.username,
        password: data.password,
        lang: null,
      })
        .then((res) => {
          AppStorage.authToken.set(res.data);
          AppStorage.tokenStatus.set(AuthTokenEnum.valid);
          registerCheck(update).then(() => {
            resolve(true);
          });
        })
        .catch(() => {
          toast.error("خطایی به وجود آمده است. لطفا مجددا تلاش نمایید.");
          resolve(true);
        });
    });
  }
  return (
    <div className="sign-in-page-main-container">
      <div className="art-section d-pre-md-none">
        <div style={{ backgroundImage: `url(${banner})` }}>
          <div className="p-1">
            <h5 className="typo-h5 mb-2 typo-white">پوشینه توسعه کسری</h5>
            <p className="typo-p3 typo-gray4 typo-white">
              پنل مدیریت مدیریت محتوای وبسایت پوشینه
            </p>
          </div>
        </div>
      </div>
      <div className="form-section">
        <div className="form">
          <div className="header mb-4">
            <div>
              <h5 className="typo-h5 mb-1">ورود</h5>
              <p className="typo-p3 typo-gray-3">
                اطلاعات کاربری را وارد نمایید.
              </p>
            </div>
            <TbUserCheck className="typo-h5 typo-primary-light" />
          </div>
          <InputComp control={control} name="username" label="ایمیل" />
          <InputComp
            control={control}
            name="password"
            type={passwordVisibility ? "text" : "password"}
            label="پسورد"
            endIcon={
              passwordVisibility ? (
                <HiEyeOff
                  role="button"
                  onClick={() => setPasswordVisibility(false)}
                />
              ) : (
                <HiEye
                  role="button"
                  onClick={() => setPasswordVisibility(true)}
                />
              )
            }
          />
          <ButtonComp className="mb-2-i" onClick={handleSubmit(onClickHandler)}>
            ورود
          </ButtonComp>
        </div>
      </div>
    </div>
  );
};

export default SignInComp;
