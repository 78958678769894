export const __editorLang = {
  code: "fa",
  toolbar: {
    default: "پیشفرض",
    save: "ذخیره",
    font: "فونت",
    formats: "فرمت ها",
    fontSize: "اندازه",
    bold: "بولد",
    underline: "آندرلاین",
    italic: "ایتالیک",
    strike: "باطل شده",
    subscript: "زیر وند",
    superscript: "رووند",
    removeFormat: "حذف ویژگی ها",
    fontColor: "رنگ متن",
    hiliteColor: "رنگ هایلایت",
    indent: "تو رفتگی",
    outdent: "بیرون زدگی",
    align: "جهت جینش",
    alignLeft: "چپ چین",
    alignRight: "راست چین",
    alignCenter: "وسط چین",
    alignJustify: "جاستیفای",
    list: "لیست",
    orderList: "لیست مرتب",
    unorderList: "لیست نامرتب",
    horizontalRule: "خط افقی",
    hr_solid: "تو پر",
    hr_dotted: "نقطه چین",
    hr_dashed: "خط چین",
    table: "جدول",
    link: "لینک",
    math: "ریاضی",
    image: "عکس",
    video: "ویدیو",
    audio: "صوت",
    fullScreen: "تمام صفحه",
    showBlocks: "نمایش بلوک ها",
    codeView: "نمایش کد",
    undo: "بازگشت به عقب",
    redo: "حرکت به جلو",
    preview: "پیشنمایش",
    print: "چاپ",
    tag_p: "پاراگراف",
    tag_div: "عادی",
    tag_h: "عنوان",
    tag_blockquote: "نقل قول",
    tag_pre: "کد",
    template: "قالب",
    lineHeight: "ارتفاع خط",
    paragraphStyle: "استایل پاراگراف",
    textStyle: "استایل متن",
    imageGallery: "گالری عکس",
    dir_ltr: "چپ به راست",
    dir_rtl: "راست به چپ",
    mention: "منشن",
  },
  dialogBox: {
    linkBox: {
      title: "افزودن لینک",
      url: "یو آر ال لینک",
      text: "عنوان لینک",
      newWindowCheck: "بازشدن لینک در پنجره جدید",
      downloadLinkCheck: "لینک دانلود",
      bookmark: "بوکمارک",
    },
    mathBox: {
      title: "ریاضیات",
      inputLabel: "علائم ریاضی",
      fontSizeLabel: "اندازه فونت",
      previewLabel: "پیشنمایش",
    },
    imageBox: {
      title: "افزودن تصویر",
      file: "انتخاب از فایل ها",
      url: "یوآرال تصویر",
      altText: "متن جایگزین",
    },
    videoBox: {
      title: "افزودن ویدیو",
      file: "انتخاب از فایل ها",
      url: "لینک ویدیو / لینک یوتیوب",
    },
    audioBox: {
      title: "عنوان صوت",
      file: "انتخاب از فایل ها",
      url: "یوآرال صوت",
    },
    browser: {
      tags: "تگ ها",
      search: "جست و جو",
    },
    caption: "افزودن توضیحات",
    close: "بستن",
    submitButton: "تایید",
    revertButton: "معکوس سازی",
    proportion: "محدودیت نسبت",
    basic: "ابتدایی",
    left: "چپ",
    right: "راست",
    center: "مرکز",
    width: "عرض",
    height: "ارتفاع",
    size: "اندازه",
    ratio: "نسبت",
  },
  controller: {
    edit: "ویرایش",
    unlink: "حذف لینک",
    remove: "حذف",
    insertRowAbove: "افزودن ردیف بالایی",
    insertRowBelow: "افزودن ردیف پایینی",
    deleteRow: "حذف ردیف",
    insertColumnBefore: "افزودن ستون پیشینی",
    insertColumnAfter: "افزودن ستون بعدی",
    deleteColumn: "حذف ستون",
    fixedColumnWidth: "ستون عرض ثابت",
    resize100: "تغییر اندازه 100%",
    resize75: "تغییر اندازه 75%",
    resize50: "تغییر اندازه 50%",
    resize25: "تغییر اندازه 25%",
    autoSize: "اندازه اتوماتیک",
    mirrorHorizontal: "آیینه افقی",
    mirrorVertical: "آیینه عمودی",
    rotateLeft: "چرخش به چپ",
    rotateRight: "چرخش به راست",
    maxSize: "حداکثر اندازه",
    minSize: "حداقل اندازه",
    tableHeader: "سر جدول",
    mergeCells: "ادغام سلول ها",
    splitCells: "تفکیک سلول ها",
    HorizontalSplit: "تفکیک افقی",
    VerticalSplit: "تفکیک عمودی",
  },
  menu: {
    spaced: "فاصله دار",
    bordered: "حاشیه دار",
    neon: "نئون",
    translucent: "نیمه شفاف",
    shadow: "سایه",
    code: "کد",
  },
};
