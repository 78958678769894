import React, { useContext, useState } from "react";
import { APIPath, FormsConfig, ListsConfig, RoutePath } from "data";
import { useHistory, useParams } from "react-router-dom";
import { FormBuilderComp, ListBuilderComp, PageBuilderComp } from "components";
import { MdAdd } from "react-icons/md";
import { GlobalContext } from "context";
import { AppContextActionKeyEnum, BlogCategoryType } from "types";

const EditBlogCategoryComp: React.FC = function () {
  const { id } = useParams<any>();
  const { update } = useContext(GlobalContext);
  const [category, setCategory] = useState<BlogCategoryType>();
  const history = useHistory();

  return (
    <PageBuilderComp
      title="ویرایش دسته بندی"
      breadcrumb={[
        { title: "پنل مدیریت", path: RoutePath.home },
        { title: "دسته بندی وبلاگ", path: RoutePath.blogCategory.index },
        { title: "ویرایش دسته بندی" },
      ]}
    >
      <FormBuilderComp
        initParser={FormsConfig.blogCategory.initParser}
        groups={FormsConfig.blogCategory.groups}
        onLoad={(e: BlogCategoryType) => setCategory(e)}
        submitParser={FormsConfig.blogCategory.submitParser}
        api={APIPath.blogCategory(id)}
        actions={[
          { title: "بازگشت", onClick: "back" },
          {
            title: "ویرایش",
            submit: {
              enable: true,
              success: "دسته بندی با موفقیت ویرایش شد",
            },
          },
        ]}
      />
      {category && category.id ? (
        <PageBuilderComp
          className="mt-8"
          title="پست های این دسته"
          style={{ padding: 0, marginTop: 24 }}
          actions={[
            {
              title: "ایجاد پست جدید",
              icon: () => <MdAdd />,
              onClick: () => {
                update({
                  key: AppContextActionKeyEnum.formDefault,
                  value: [
                    {
                      key: "category",
                      value: { payload: category?.id, title: category?.title },
                    },
                  ],
                });
                history.push(RoutePath.blogPost.create);
              },
            },
          ]}
        >
          <ListBuilderComp
            pageSize={10}
            fields={ListsConfig.post.fields}
            defaultFilters={{ category: category.id }}
            api={APIPath.post()}
            deleteAction={(e: BlogCategoryType) => APIPath.post(e.slug)}
            editAction={(e: BlogCategoryType) =>
              `${RoutePath.blogPost.edit(e.slug)}`
            }
          />
        </PageBuilderComp>
      ) : null}
    </PageBuilderComp>
  );
};
export default EditBlogCategoryComp;
