import { RoutePath } from "data";
import React from "react";
import { Route, Switch } from "react-router-dom";
import CreateProduct from "./create/create.index";
import EditProduct from "./edit/edit.index";
import ListProduct from "./list/list.index";
const ProductComp: React.FC = function () {
  return (
    <Switch>
      <Route path={RoutePath.product.create}>
        <CreateProduct />
      </Route>
      <Route path={RoutePath.product.edit()}>
        <EditProduct />
      </Route>
      <Route path={"*"}>
        <ListProduct />
      </Route>
    </Switch>
  );
};
export default ProductComp;
