import {
  ProductCategoryType,
  BlogCategoryType,
  BlogPostType,
  ProductAPIType,
  TechnicalSpecificationType,
  FileAttachmentType,
  FileTypeAttachmentEnum,
} from "types";
import * as yup from "yup";
import { FormBuilderProps } from "components";
import { APIPath } from "data";
import { __ListsConfig } from "./lists";

interface FormType {
  groups: FormBuilderProps["groups"];
  initParser?: FormBuilderProps["initParser"];
  submitParser?: FormBuilderProps["submitParser"];
}

const productParentCategory: (isParent?: boolean) => FormType = (
  isParent?: boolean
) => ({
  groups: [
    {
      fields: [
        {
          key: "title",
          title: "نام دسته بندی",
          validation: yup
            .string()
            .required("لطفا نام دسته بندی را وارد نمایید"),
          type: "text",
        },
        {
          key: "slug",
          title: "آدرس دسته بندی",
          validation: yup.string(),
          type: "slug",
          prefix: "/products-category/",
        },
        {
          key: "image",
          title: "تصویر",
          validation: yup.object().nullable(),
          type: "singleFileAttachment",
          accept: "image/*",
        },
        // {
        //   key: "parent",
        //   title: "دسته بندی والد",
        //   validation: yup.object().nullable(),
        //   type: "singleAutoComplete",
        //   disabled: isParent,
        //   api: (slug) => APIPath.productCategory(slug),
        //   listProps: {
        //     fields: __ListsConfig.productCategory.fields,
        //     defaultFilters: { parent__isnull: true },
        //     convertToSelectOption: (fetchedData: ProductCategoryType) => ({
        //       title: fetchedData.title,
        //       payload: fetchedData.id,
        //     }),
        //   },
        // },
        {
          key: "description",
          title: "توضیحات",
          validation: yup.string(),
          type: "textarea",
        },
      ],
    },
  ],
  initParser: (e) => {},
  submitParser: (e) => ({
    slug: e.slug && e.slug.length ? e.slug.split(" ").join("-") : undefined,
    parent: e.parent ? e.parent.payload : undefined,
    image: e.image?.id,
  }),
});

const productChildCategory: FormType = {
  groups: [
    {
      fields: [
        {
          key: "title",
          title: "نام دسته بندی",
          validation: yup
            .string()
            .required("لطفا نام دسته بندی را وارد نمایید"),
          type: "text",
        },
        {
          key: "slug",
          title: "آدرس دسته بندی",
          validation: yup.string(),
          type: "slug",
          prefix: "/products-category/",
        },
        {
          key: "image",
          title: "تصویر",
          validation: yup.object().required("لطفا تصویر دسته را وارد کنید"),
          type: "singleFileAttachment",
          accept: "image/*",
        },
        {
          key: "parent",
          title: "دسته بندی والد",
          validation: yup.object(),
          type: "singleAutoComplete",
          api: (id) => APIPath.productCategory(id),
          listProps: {
            fields: __ListsConfig.productCategory.fields,
            defaultFilters: { parent__isnull: true },
            convertToSelectOption: (fetchedData: ProductCategoryType) => ({
              title: fetchedData.title,
              payload: fetchedData.slug,
            }),
          },
          formProps: {
            groups: () => productChildCategory.groups,
            initParser: undefined,
            submitParser: undefined,
          },
        },
        {
          key: "description",
          title: "توضیحات",
          validation: yup.string(),
          type: "textarea",
        },
      ],
    },
  ],
  initParser: (e: ProductCategoryType) => ({
    parent: e.parent
      ? { title: e.parent.title, payload: e.parent.id }
      : undefined,
  }),
  submitParser: (e) => ({
    slug: e.slug && e.slug.length ? e.slug.split(" ").join("-") : undefined,
    parent: e.parent?.payload,
    image: e.image?.id,
  }),
};

const product: FormType = {
  groups: [
    {
      fields: [
        {
          key: "title",
          title: "نام محصول",
          validation: yup.string().required("لطفا نام محصول را وارد نمایید"),
          type: "text",
        },
        {
          key: "description",
          title: "توضیحات",
          validation: yup.string(),
          type: "textarea",
        },
        {
          key: "slug",
          title: "آدرس محصول",
          validation: yup.string(),
          type: "slug",
          prefix: "/products/",
        },
        {
          key: "main_image",
          title: "تصویر شاخص",
          validation: yup.object().required("لطفا تصویر محصول را وارد کنید"),
          type: "singleFileAttachment",
          accept: "image/*",
        },
        {
          key: "images",
          title: "گالری",
          validation: yup.array().nullable(),
          type: "multiFileAttachment",
          accept: "image/*",
          limit: 5,
        },
        {
          key: "category",
          title: "دسته بندی",
          validation: yup.object().required("لطفا دست بندی را وارد نمایید"),
          type: "singleAutoComplete",
          api: (id) => APIPath.productCategory(id),
          listProps: {
            fields: __ListsConfig.productCategory.fields,
            convertToSelectOption: (fetchedData: ProductCategoryType) => ({
              title: fetchedData.title,
              payload: fetchedData.id,
            }),
            defaultFilters: { parent__isnull: false },
          },
          formProps: {
            groups: () => productChildCategory.groups,
            initParser: productChildCategory.initParser,
            submitParser: productChildCategory.submitParser,
          },
        },
        {
          key: "features",
          title: "ویژگی های محصول",
          validation: yup
            .string()
            .required("لطفا ویژگی های محصول را وارد نمایید"),
          type: "textarea",
        },
        {
          key: "applications",
          title: "کاربردهای محصول",
          validation: yup
            .string()
            .required("لطفا کاربردهای محصول را وارد نمایید"),
          type: "textarea",
        },
        {
          key: "benefits",
          title: "فواید محصول",
          validation: yup.string().required("لطفا فواید محصول را وارد نمایید"),
          type: "textarea",
        },
        {
          key: "technical_specification",
          title: "پارامترهای محصول",
          validation: yup.array().nullable(),
          type: "tableInput",
          default: [
            { serial_number: "", parameters: "", unit: "", standard: "" },
          ],
        },
        {
          key: "content",
          title: "محتوا",
          validation: yup.string().required("لطفا محتوا پست را وارد نمایید"),
          type: "textEditor",
        },
      ],
    },
  ],
  initParser: (e: ProductAPIType) => ({
    features: e.features.join("\n"),
    benefits: e.benefits.join("\n"),
    applications: e.applications.join("\n"),
    category: e.category
      ? { ...e.category, payload: e.category.id }
      : undefined,
    technical_specification: e.technical_specification.list.length
      ? e.technical_specification.list
      : [{ parameters: "", serial_number: "", standard: "", unit: "" }],
  }),
  submitParser: (e) => ({
    main_image: e.main_image?.id,
    images:
      e.images && e.images.length
        ? e.images.map((i: FileAttachmentType) => i.id)
        : undefined,
    category: e.category ? e.category.payload : null,
    features: e.features.split("\n"),
    benefits: e.benefits.split("\n"),
    slug: e.slug && e.slug.length ? e.slug.split(" ").join("-") : undefined,
    applications: e.applications.split("\n"),
    technical_specification: {
      list: e.technical_specification.filter(
        (i: TechnicalSpecificationType) =>
          i.parameters.length +
            i.serial_number.length +
            i.standard.length +
            i.unit.length >
          0
      ),
    },
  }),
};

const blogCategory: FormType = {
  groups: [
    {
      fields: [
        {
          key: "title",
          title: "نام دسته بندی",
          validation: yup
            .string()
            .required("لطفا نام دسته بندی را وارد نمایید"),
          type: "text",
        },
        {
          key: "slug",
          title: "آدرس دسته بندی",
          validation: yup.string(),
          type: "slug",
          prefix: "/post-category/",
        },
        {
          key: "parent",
          title: "دسته بندی والد",
          validation: yup.object().nullable(),
          type: "singleAutoComplete",
          api: (id) => APIPath.blogCategory(id),
          listProps: {
            fields: __ListsConfig.blogCategory.fields,
            convertToSelectOption: (fetchedData: BlogCategoryType) => ({
              title: fetchedData.title,
              payload: fetchedData.slug,
            }),
          },
          formProps: {
            groups: () => blogCategory.groups,
            initParser: (e: BlogCategoryType) => ({
              parent: e.parent
                ? { title: e.parent.title, payload: e.parent.id }
                : undefined,
            }),
            submitParser: (e) => ({ parent: e.parent.payload }),
          },
        },
        {
          key: "description",
          title: "توضیحات",
          validation: yup.string(),
          type: "textarea",
        },
      ],
    },
  ],
  initParser: (e: BlogCategoryType) => ({
    parent: e.parent
      ? { title: e.parent.title, payload: e.parent.id }
      : undefined,
  }),
  submitParser: (e) => ({
    slug: e.slug && e.slug.length ? e.slug.split(" ").join("-") : undefined,
    parent: e.parent?.payload,
  }),
};

const post: FormType = {
  groups: [
    {
      fields: [
        {
          key: "title",
          title: "عنوان پست",
          validation: yup.string().required("لطفا عنوان پست را وارد نمایید"),
          type: "text",
        },
        {
          key: "slug",
          title: "آدرس پست",
          validation: yup.string(),
          type: "slug",
          prefix: "/post/",
        },
        {
          key: "description",
          title: "توضیحات",
          validation: yup.string(),
          type: "textarea",
        },
        {
          key: "image",
          title: "تصویر",
          validation: yup.object().nullable(),
          type: "singleFileAttachment",
          accept: "image/*",
        },
        {
          key: "category",
          title: "دسته بندی",
          validation: yup.object().required("لطفا دست بندی را وارد نمایید"),
          type: "singleAutoComplete",
          api: (id) => APIPath.blogCategory(id),
          listProps: {
            fields: __ListsConfig.blogCategory.fields,
            convertToSelectOption: (fetchedData: BlogCategoryType) => ({
              title: fetchedData.title,
              payload: fetchedData.slug,
            }),
          },
          formProps: {
            groups: () => blogCategory.groups,
            initParser: blogCategory.initParser,
            submitParser: blogCategory.submitParser,
          },
        },
        {
          key: "content",
          title: "محتوا",
          validation: yup.string().required("لطفا محتوا پست را وارد نمایید"),
          type: "textEditor",
        },
      ],
    },
  ],
  initParser: (e: BlogPostType) => ({
    category: e.category
      ? { ...e.category, payload: e.category.slug }
      : undefined,
  }),
  submitParser: (e) => ({
    image: e.image?.id,
    slug: e.slug && e.slug.length ? e.slug.split(" ").join("-") : undefined,
    category: e.category ? e.category.payload : null,
  }),
};

const contactForm: FormType = {
  groups: [
    {
      fields: [
        {
          key: "last_name",
          title: "نام و نام خانوادگی کاربر",
          validation: yup.string(),
          type: "text",
          disabled: true,
        },
        {
          key: "email",
          title: "ایمیل کاربر",
          validation: yup.string(),
          type: "text",
          disabled: true,
        },
        {
          key: "phone",
          title: "ایمیل کاربر",
          validation: yup.string(),
          type: "text",
          disabled: true,
        },
        {
          key: "description",
          title: "توضیحات",
          validation: yup.string(),
          type: "textarea",
          disabled: true,
        },
      ],
    },
  ],
  initParser: () => {},
};

const workWithUsForm: FormType = {
  groups: [
    {
      fields: [
        {
          key: "subject",
          title: "موضوع درخواست",
          validation: yup.string(),
          type: "text",
          disabled: true,
        },
        {
          key: "fullname",
          title: "نام و نام خانوادگی کاربر",
          validation: yup.string(),
          type: "text",
          disabled: true,
        },
        {
          key: "company_name",
          title: "نام شرکت",
          validation: yup.string(),
          type: "text",
          disabled: true,
        },
        {
          key: "email",
          title: "ایمیل کاربر",
          validation: yup.string(),
          type: "text",
          disabled: true,
        },
        {
          key: "description",
          title: "توضیحات",
          validation: yup.string(),
          type: "textarea",
          disabled: true,
        },
      ],
    },
  ],
  initParser: () => {},
};

const gallery: FormType = {
  groups: [
    {
      fields: [
        {
          key: "file",
          title: "تصویر",
          validation: yup.object().required("لطفا تصویر را وارد کنید"),
          type: "singleFileAttachment",
          accept: "image/*",
        },
        {
          key: "order",
          title: "ردیف در اولویت نمایش",
          validation: yup.number().required("لطفا شماره تصویر را وارد کنید"),
          type: "text",
        },
      ],
    },
  ],
  initParser: () => {},
  submitParser: (e) => ({ file: e.file?.id, lang: undefined }),
};

const customer: FormType = {
  groups: [
    {
      fields: [
        {
          key: "title",
          title: "عنوان مشتری",
          validation: yup.string().required("لطفا عنوان مشتری را وارد کنید"),
          type: "text",
        },
        {
          key: "image",
          title: "لوگو",
          validation: yup.object().required("لطفا تصویر را وارد کنید"),
          type: "singleFileAttachment",
          accept: "image/*",
        },
      ],
    },
  ],
  initParser: () => {},
  submitParser: (e) => ({ image: e.image?.id }),
};

export const __FormsConfig = {
  product,
  productParentCategory,
  productChildCategory,
  post,
  blogCategory,
  contactForm,
  workWithUsForm,
  gallery,
  customer,
};
